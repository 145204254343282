/* eslint-disable no-unused-expressions */
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, batch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField, LocationPlacesList } from 'components';
import {
  closeSidebarChoseStartPoint,
  openSidebarPlanRoute,
} from 'store/map/actions';
import { fetchPlaceDetails, getPlaceName, searchPlace } from 'services';
import {
  ArrowBack,
  CurrentLocationIcon,
  LocationArrowIcon,
  LocationIcon,
} from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { START_WAYPOINT_TYPE } from 'utils/constants';
import {
  CurrentLocationContainer,
  HeaderText,
} from './SidebarChoseStartPoint.styles';
import { ISidebarChoseStartPoint } from './ISidebarChoseStartPoint';
import { MapSidebarWrap } from '../index';
import { v4 as uuidv4 } from 'uuid';
const SidebarChoseStartPoint = ({
  userCoordinates,
  viewport,
  setViewport,
  setStartPointCoordinates,
  startPoint,
  temporaryWaypoint,
  setTemporaryWaypoint,
  isEdit,
  editCurrentWaypoint,
}: ISidebarChoseStartPoint) => {
  const sessionToken = useRef(uuidv4());
  const [placesOffered, setPlacesOffered] = useState([]);
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const [startPointAddress, setStartPointAddress] = useState(
    (temporaryWaypoint || startPoint)?.waypointName || ''
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const inputRef = useRef(null);

  useEffect(() => {
    if (temporaryWaypoint) {
      setStartPointAddress(temporaryWaypoint.waypointName);
    }
  }, [temporaryWaypoint]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  const removeStartPoint = () => {
    if (temporaryWaypoint) {
      setTemporaryWaypoint(null);
    }
  };

  const onChangePlace = (newText) => {
    setStartPointAddress(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
        limit: 10,
        sessionToken: sessionToken.current,
      });

      if (!resp) {
        return null;
      }

      const { predictions } = resp?.data;
      if (predictions?.length) {
        setPlacesOffered(predictions);
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      removeStartPoint();
    }

    setIsCurrentLocation(false);
  };

  const onPlaceOfferedClick = async (place) => {
    const response = await fetchPlaceDetails({
      placeId: place?.place_id,
      sessionToken: sessionToken.current,
    });
    if (!response) {
      return null;
    }
    const { data } = response;
    if (data) selectedPlace(data);
  };

  const selectedPlace = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setStartPointAddress(place?.formatted_address);

    const { lat, lng } = place?.geometry?.location;

    setTemporaryWaypoint({
      lat: lat,
      lng: lng,
      waypointName: place?.formatted_address,
      placeId: place?.place_id,
      type: START_WAYPOINT_TYPE,
    });
    setViewport({ ...viewport, latitude:lat, longitude:lng, zoom: 13 });
  };

  const onCurrentLocationClick = async () => {
    const [longitude, latitude] = userCoordinates;

    const { features } = await getPlaceName({ coordinates: userCoordinates });

    let newAddress;

    if (Array.isArray(features) && features?.length) {
      if (features[0].place_type[0]) {
        newAddress = features[0].place_name;
      } else {
        newAddress = `${userCoordinates[0].toFixed(
          6
        )},${userCoordinates[1].toFixed(6)}`;
      }
    }

    setTemporaryWaypoint({
      lat: latitude,
      lng: longitude,
      waypointName: newAddress,
      placeId: features[0]?.id,
      type: START_WAYPOINT_TYPE,
    });

    setStartPointAddress(newAddress);
    setViewport({ ...viewport, latitude, longitude, zoom: 16 });
    setIsCurrentLocation(true);
  };

  const onCloseSidebarClick = async () => {
    batch(() => {
      dispatch(closeSidebarChoseStartPoint());
      dispatch(openSidebarPlanRoute());
    });

    removeStartPoint();
  };

  const getInputLeftIcon = () => {
    if (isCurrentLocation) {
      return <CurrentLocationIcon color={style.mainGray.SuvaGrey} />;
    }

    return temporaryWaypoint ? <LocationIcon /> : <LocationArrowIcon />;
  };

  const onSubmit = () => {
    setStartPointCoordinates({ ...temporaryWaypoint });
    setTemporaryWaypoint(null);
    batch(() => {
      dispatch(closeSidebarChoseStartPoint());
      dispatch(openSidebarPlanRoute());
    });
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={t('map:chooseStartPoint')}
        headerIcon={<ArrowBack />}
        submitText={t('common:add')}
        isSubmitAvailable={Boolean(temporaryWaypoint)}
        onSubmit={onSubmit}
        onIconClick={onCloseSidebarClick}
        contentPadding="20px"
      >
        <InputField
          value={startPointAddress}
          onChange={(e) => onChangePlace(e.target.value)}
          iconLeft={getInputLeftIcon()}
          placeholder={t('map:typeAPlace')}
          label={t('map:searchLocation')}
          crossIcon
          onCrossClick={() => {
            setStartPointAddress('');
            removeStartPoint();
            setPlacesOffered([]);
            setIsOfferedPlacesModalOpen(false);
            setIsCurrentLocation(false);
          }}
          ref={inputRef}
        />

        <CurrentLocationContainer onClick={onCurrentLocationClick}>
          <CurrentLocationIcon />

          <HeaderText ml={12}>{t('map:useCurrentLocation')}</HeaderText>
        </CurrentLocationContainer>

        {isOfferedPlacesModalOpen && (
          <LocationPlacesList
            locationsList={placesOffered}
            onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
            onItemClick={(item) => onPlaceOfferedClick(item)}
            top={86}
            left={20}
          />
        )}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarChoseStartPoint;
