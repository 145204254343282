import styled from 'styled-components';

import { style } from 'utils/constants/style';

interface IProps {
  margin?: string;
  iconLeft?: boolean;
  height?: number;
  isLabelExists?: boolean;
  crossIcon?: boolean;
  backgroundColor?: string;
  focus?: boolean;
  pl?: number;
  cursor?: string;
}

export const InputWrapper = styled.div<IProps>`
  width: 100%;
  position: relative;
  margin: ${(props) => props.margin || '0'};
  text-align: left;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label`
  color: ${style.mainBlack.charcoalColor};
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 4px;
`;

export const InputField = styled.input<IProps>`
  width: 100%;
  box-sizing: border-box;
  border-radius: 4px;
  height: ${({ height }) => (height ? `${height}px` : '44px')};
  background-color: ${({ backgroundColor }) =>
    backgroundColor || style.mainGray.whiteSmokeColor};
  color: ${style.mainBlack.black};
  padding: ${({ iconLeft }) => (iconLeft ? '0 16px 0 38px' : '0 5px 0 16px')};
  padding-right: ${({ crossIcon }) => (crossIcon ? '34px' : '16px')};
  padding-left: ${({ pl, iconLeft }) => {
    if (pl) {
      return `${pl}px`;
    }

    return iconLeft ? '38px' : '16px';
  }};
  font-size: 15px;
  border: none;
  outline: none;
  font-family: 'Poppins';
  cursor: ${({ cursor, disabled }) =>
    disabled ? 'not-allowed' : cursor || 'auto'};

  &:focus {
    border: 1px solid ${style.mainYellow.yellow};
    border: ${({ focus }) => focus && `none`};
  }
`;

export const TextAreaInput = styled.textarea<IProps>`
  width: 100%;
  height: ${({ height }) => (height ? `${height}px` : '88px')};
  box-sizing: border-box;
  padding: 15px 16px;
  padding-right: ${({ crossIcon }) => (crossIcon ? '29px' : '16px')};
  font-size: 15px;
  line-height: 15px;
  border-radius: 4px;
  background-color: ${style.mainGray.whiteSmokeColor};
  color: ${style.mainBlack.black};
  resize: none;
  border: none;
  outline: none;
  font-family: 'Poppins';

  &:focus {
    border: 1px solid ${style.mainYellow.yellow};
  }
`;

export const LeftIconWrapper = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 10px;
  left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CrossIconWrapper = styled.div<IProps>`
  width: 24px;
  height: 24px;
  position: absolute;
  bottom: 50%;
  margin-bottom: ${({ isLabelExists }) =>
    isLabelExists ? '-20.5px' : '-12px'};
  right: 5px;
  cursor: pointer;
`;

export const CharactersNumber = styled.p`
  font-size: 12px;
  line-height: 12px;
  color: ${style.mainGray.silver};
  margin-top: 6px;
  align-self: flex-end;
`;
export const LoaderContainer = styled.div`
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  right:20px;
`;
