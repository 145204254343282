import { useEffect } from 'react';
import ReactDom from 'react-dom';

import { ArrowBack, CrossIcon } from 'public/assets/icons';
import Button from 'components/common/Button';
import { IDrawer } from './IDrawer';

import {
  DrawerHeader,
  DrawerWrapper,
  HeaderIconWrapper,
  HeaderText,
  HeaderTextWithIcon,
  DrawerContent,
  DrawerOverlay,
} from './Drawer.styles';

const Drawer = ({
  open,
  headerText,
  isHeaderBorder = true,
  disabled = false,
  loading,
  isCross = false,
  onDrawerClose,
  onSubmit,
  onIconClick,
  submitText,
  children,
  isDrawerOpen,
  rootForm = false,
  className,
}: IDrawer) => {
  if (submitText && !onSubmit) {
    throw new Error('Drawer required onSubmit function!');
  }

  useEffect(() => {
    if (open) {
      document.body.style.overflow = 'hidden';
    }

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, [open]);

  const drawerContent = open ? (
    <DrawerOverlay
      onClick={(e) => {
        e.stopPropagation();
        if (onDrawerClose) {
          onDrawerClose();
        }
      }}
    >
      <DrawerWrapper open={open} onClick={(e) => e.stopPropagation()}>
        {rootForm ? (
          children
        ) : (
          <>
            <DrawerHeader headerBorder={isHeaderBorder}>
              <HeaderTextWithIcon>
                <HeaderIconWrapper onClick={onIconClick}>
                  {isCross ? <CrossIcon /> : <ArrowBack />}
                </HeaderIconWrapper>
                <HeaderText type="h3">{headerText}</HeaderText>
              </HeaderTextWithIcon>
              {submitText && (
                <HeaderIconWrapper>
                  <Button
                    size="sm"
                    disabled={disabled}
                    loading={loading}
                    onClick={onSubmit}
                  >
                    {submitText}
                  </Button>
                </HeaderIconWrapper>
              )}
            </DrawerHeader>
            <DrawerContent className={className}>{children}</DrawerContent>
          </>
        )}
      </DrawerWrapper>
    </DrawerOverlay>
  ) : null;

  if (typeof window !== 'undefined') {
    return ReactDom.createPortal(
      drawerContent,
      document.getElementById('modal-root')
    );
  }

  return null;
};

export default Drawer;
