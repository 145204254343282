import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const postLogin = (nameOrEmail: string, password: string) =>
  fetchData(`${process.env._DB_HOST}api/v11/auth/${nameOrEmail}`, {
    method: 'post',
    headers,
    body: JSON.stringify({ password }),
    isAuthenticated:false
  }).then((response) => response.json());

export const deleteLogOut = () => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v04/user`, {
      method: 'delete',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const postAuthorization = (
  email: string,
  password: string,
  referrerData: any
) => {
  const postBody: any = { password };
  if (referrerData) {
    postBody.referrerData = referrerData;
  }
  return fetchData(`${process.env._DB_HOST}api/v11/auth/${email}/new`, {
    method: 'post',
    headers,
    body: JSON.stringify({ ...postBody }),
    isAuthenticated:false
  }).then((response) => response.json());
};

export const loginWithFacebook = (accessToken) => {
  try {
    return fetchData(`${process.env._DB_HOST}api/v11/auth/facebook`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ accessToken }),
      isAuthenticated:false
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const loginWithApple = (accessToken) => {
  try {
    return fetchData(`${process.env._DB_HOST}api/v11/auth/apple`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ accessToken }),
      isAuthenticated:false
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const recoverAccount = (email) => {
  try {
    return fetchData(`${process.env._DB_HOST}api/v11/auth/${email}/reset`, {
      method: 'POST',
      headers,
      isAuthenticated:false
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const resetPassword = (data) => {
  try {
    return fetchData(`${process.env._DB_HOST}api/v11/auth/reset`, {
      method: 'POST',
      headers,
      body: JSON.stringify(data),
      isAuthenticated:false
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const activateAccount = (data) => {
  try {
    const postObj: any = { token: data.token };
    if (data.referrerData) {
      postObj.referrerData = data.referrerData;
    }
    return fetchData(`${process.env._DB_HOST}api/v11/auth/activate`, {
      method: 'POST',
      headers,
      body: JSON.stringify({ ...postObj }),
      isAuthenticated:false
    }).then((response) => response.json());
  } catch (error) {
    console.log('error');
  }
};

export const resendActivationEmail = (email) => {
  try {
    return fetchData(`${process.env._DB_HOST}api/v11/auth/${email}/activate`, {
      method: 'POST',
      headers,
      isAuthenticated:false
    }).then((response) => response.json());
  } catch (error) {
    console.log('Error in resending activation email:', error);
  }
};
