import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ValidationError from 'components/ValidationError';
import { CircleWithEditIcon, PlusIcon } from 'public/assets/icons';
import { IFormElect } from './Types';

import {
  Wrapper,
  Title,
  IconButton,
  RenderContent,
  BarWrapper,
  RenderContentDrawer,
  BarIcon,
  BarLeft,
} from './styles';

const FormElect = ({
  id,
  label,
  render,
  meta,
  input,
  formRender,
  headerText,
  multipleSelect = true,
  isDisabled = false,
  barIcon,
  disabledSubmitBtn =false
}: IFormElect) => {
  const [open, setOpen] = useState(false);
  const [inputCurentValue, setInputCurentValue] = useState<any>();
  const { onChange } = input;

  const { t } = useTranslation();

  const { valid, invalid, touched, error, submitError } = meta;

  const errorText = error || submitError;

  // Error message and input error styles are only shown if the
  // field has been touched and the validation has failed.
  const hasError = !!(touched && invalid && (error || submitError));

  const onSelect = (v) => {
    if (multipleSelect) {
      const checkPrevious = inputCurentValue?.filter((b) => b?.id === v?.id);
      if (checkPrevious?.length > 0) {
        const getFilterValue = inputCurentValue?.filter((b) => b?.id !== v?.id);
        setInputCurentValue(getFilterValue);
        return;
      }
      if (inputCurentValue?.length > 0) {
        setInputCurentValue([...inputCurentValue, v]);
        return;
      }
      setInputCurentValue([v]);
      return;
    }
    setInputCurentValue(v);
  };

  const onRemove = (v) => {
    if (multipleSelect) {
      const getFilterValue = input.value?.filter((b) => b?.id !== v?.id);
      setInputCurentValue(getFilterValue);
      onChange(getFilterValue);
      return;
    }
    setInputCurentValue('');
    onChange('');
  };

  const onDrawerClose = () => {
    setOpen(false);
    if (multipleSelect) {
      setInputCurentValue([]);
    }
    // setInputCurentValue('');
  };

  // TODO: Need to remove this effect for multipale select
  useEffect(() => {
    if (input?.value?.length > 0 && input?.value !== undefined) {
      setInputCurentValue(input.value);
    }
  }, [input?.value]);

  return (
    <>
      <Wrapper>
        <BarWrapper>
          <BarLeft>
            {barIcon && <BarIcon>{barIcon}</BarIcon>}
            <Title type="h4">{label}</Title>
          </BarLeft>
          <IconButton
            type="button"
            onClick={() => setOpen(true)}
            disabled={isDisabled}
            hasError={hasError}
          >
            {input?.value?.length > 0 && input?.value !== undefined ? (
              <CircleWithEditIcon />
            ) : (
              <PlusIcon />
            )}
          </IconButton>
        </BarWrapper>
        {render && (
          <RenderContent>{render(input.value, onRemove)}</RenderContent>
        )}
        <ValidationError touched={hasError} error={errorText} />
      </Wrapper>
      <RenderContentDrawer
        open={open}
        headerText={headerText}
        onIconClick={onDrawerClose}
        onDrawerClose={onDrawerClose}
        submitText={t('editUserProfile:save')}
        disabled={disabledSubmitBtn}
        onSubmit={() => {
          onChange(inputCurentValue);
          onDrawerClose();
        }}
      >
        {formRender(inputCurentValue, onSelect)}
      </RenderContentDrawer>
    </>
  );
};

export default FormElect;
