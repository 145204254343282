import { Moment } from 'moment';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';

export const getDateInOriginalTimezone = (date: Moment) => {
  if (!date || !date.isValid()) {
    console.error('Invalid moment date:', date);
    return new Date();
  }
  // Use Date.UTC to create a UTC-based date
  const exactDate = new Date(
    date.year(),
    date.month(), // Months are 0-indexed in JavaScript, so no adjustment needed
    date.date(),
    date.hour(),
    date.minute(),
    date.second()
  );
  return exactDate;
};

export const getTimezoneCode = (offset: string) => {
  const minimalTimeOne = minimalTimezoneSet.find((d) => d?.offset === offset);
  if (!minimalTimeOne) return '';

  const { tzCode } = minimalTimeOne;
  return tzCode;
};
