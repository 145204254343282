import { HYDRATE } from 'next-redux-wrapper';
import _ from 'lodash';
import Immutable from 'seamless-immutable';

import { AsyncStatus } from 'utils/types';
import { convertMessages } from 'utils/helpers';
import { actionTypes } from './actions';

const initialState = {
  chats: {
    page: 0,
    isAllLoaded: false,
    data: [],
    next: [],
  },
  forNext: false,
  chatsNext: null,
  messages: {},
  chatDetails: null,
  chatMembers: null,
  messagesNext: null,
  searchedUsers: null,
  searchedUsersNext: null,
  roomInfo: null,

  createdChatInfo: null,

  selectedChatId: null,
  forNextPageMessages: false,

  getChatsReqStat: AsyncStatus.NONE,
  getChatsNextReqStat: AsyncStatus.NONE,
  getMessagesReqStat: AsyncStatus.NONE,
  getChatDetailsReqStat: AsyncStatus.NONE,
  getChatMembersReqStat: AsyncStatus.NONE,
  getMessagesNextReqStat: AsyncStatus.NONE,
  searchForUsersReqStat: AsyncStatus.NONE,
  searchForUsersNextReqStat: AsyncStatus.NONE,
  createSingleChatReqStat: AsyncStatus.NONE,
  createMultipleChatReqStat: AsyncStatus.NONE,
  changeChatSettingsReqStat: AsyncStatus.NONE,
  deleteChatReqStat: AsyncStatus.NONE,
  getRoomInfoReqStat: AsyncStatus.NONE,
  editMultipleChatReqStat: AsyncStatus.NONE,
};

const getChatsUpdated = (state, index, chat) => {
  const oldChats = state.chats;
  const oldData = oldChats.data;
  let newData = [];
  if (index < 0) {
    // the chat is not existed in the list, only add it into the first
    newData = [...[chat], ...oldData];
  } else if (index === 0) {
    newData = Immutable.set(oldData, 0, chat);
  } else {
    // remove old chat at 'index' position and add new chat to first position
    newData = [
      ...[chat],
      ...oldData.slice(0, index),
      ...oldData.slice(index + 1),
    ];
  }

  return newData;
};

/* ------------- Selectors ------------- */

export const ChatSelectors = {
  getChatsNextValue: (state) => state.chat.chats.next,
  getMessagesNextValue: (state) => {
    const chatId = state.chat.selectedChatId;
    return _.get(state.chat.messages, `${chatId}.next`, null);
  },
  getUsersNextValue: (state) =>
    _.get(state.chat.searchedUsers, 'next', undefined),
  getGroupAvatarFile: (state) => state.chat.groupAvatarFile,
};

/* ------------- Reducer ------------- */

const reducer = (state = initialState, action) => {  
  switch (action.type) {
    case HYDRATE: {
      return { ...state, ...action.payload };
    }

    // GET CHATS
    case actionTypes.GET_CHATS_START:
      return {
        ...state,
        getChatsReqStat: AsyncStatus.LOADING,
        forNext: action.data,
      };

    case actionTypes.GET_CHATS_SUCCESS: {
      const { page, data: prevData } = state.chats;
      const { forNext } = state;
      const nextValue =
        !_.isNull(action.data.next) &&
        action.data.next.substring(action.data.next.lastIndexOf('/') + 1);

      return {
        ...state,
        chats: {
          page: page + 1,
          isAllLoaded: _.isNull(action.data.next),
          data: !forNext
            ? action.data.chats
            : [...prevData, ...action.data.chats],
          next: nextValue,
        },
        getChatsReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.GET_CHATS_ERROR:
      return { ...state, getChatsReqStat: AsyncStatus.ERROR };

    // GET CHATS NEXT
    case actionTypes.GET_CHATS_NEXT_START:
      return { ...state, getChatsNextReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_CHATS_NEXT_SUCCESS:
      return {
        ...state,
        chatsNext: action.data,
        getChatsNextReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_CHATS_NEXT_ERROR:
      return { ...state, getChatsNextReqStat: AsyncStatus.ERROR };

    // GET MESSAGES
    case actionTypes.GET_MESSAGES_START:
      return {
        ...state,
        forNextPageMessages: action.data.forNextPageMessages,
        getMessagesReqStat: AsyncStatus.LOADING,
      };

    case actionTypes.GET_MESSAGES_SUCCESS: {
      const { data } = action;
      const chatId = state.selectedChatId;
      const prevMessages = _.get(state, `messages.${chatId}.messages`, []);
      const prevConvertedMessages = _.get(
        state,
        `messages.${chatId}.convertedMessages`,
        []
      ).reverse();
      const forNextPageMessages = _.get(state, 'forNextPageMessages', false);
      const convertedMessages = convertMessages(data.messages);
      const nextValue =
        !_.isNull(data.next) &&
        data.next.substring(data.next.lastIndexOf('/') + 1);

      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: {
            isAllLoaded: _.isNull(data.next),
            chat: data.chat,
            messages: !forNextPageMessages
              ? data.messages
              : [...prevMessages, ...data.messages],
            convertedMessages: !forNextPageMessages
              ? convertedMessages
              : convertMessages([...prevMessages, ...data.messages]),
            currentTime: data.currentTime,
            next: nextValue,
          },
        },
        getMessagesReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.GET_MESSAGES_ERROR:
      return { ...state, getMessagesReqStat: AsyncStatus.ERROR };

    // GET CHAT DETAILS
    case actionTypes.GET_CHAT_DETAILS_START:
      return { ...state, getChatDetailsReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_CHAT_DETAILS_SUCCESS:
      return {
        ...state,
        chatDetails: action.data,
        createdChatInfo: action.data,
        getChatDetailsReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_CHAT_DETAILS_ERROR:
      return { ...state, getChatDetailsReqStat: AsyncStatus.ERROR };

    // GET CHAT MEMBERS
    case actionTypes.GET_CHAT_MEMBERS_START:
      return { ...state, getChatMembersReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_CHAT_MEMBERS_SUCCESS:
      return {
        ...state,
        chatMembers: action.data,
        getChatMembersReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_CHAT_MEMBERS_ERROR:
      return { ...state, getChatMembersReqStat: AsyncStatus.ERROR };

    // GET MESSAGES NEXT
    case actionTypes.GET_MESSAGES_NEXT_START:
      return { ...state, getMessagesNextReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_MESSAGES_NEXT_SUCCESS:
      return {
        ...state,
        messagesNext: action.data,
        getMessagesNextReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_MESSAGES_NEXT_ERROR:
      return { ...state, getMessagesNextReqStat: AsyncStatus.ERROR };

    // SEARCH FOR USERS
    case actionTypes.SEARCH_FOR_USERS_START:
      return { ...state, searchForUsersReqStat: AsyncStatus.LOADING };

    case actionTypes.SEARCH_FOR_USERS_SUCCESS:
      return {
        ...state,
        searchedUsers: action.data,
        searchForUsersReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.SEARCH_FOR_USERS_ERROR:
      return { ...state, searchForUsersReqStat: AsyncStatus.ERROR };

    // SEARCH FOR USERS NEXT
    case actionTypes.SEARCH_FOR_USERS_NEXT_START:
      return { ...state, searchForUsersNextReqStat: AsyncStatus.LOADING };

    case actionTypes.SEARCH_FOR_USERS_NEXT_SUCCESS:
      return {
        ...state,
        searchedUsersNext: action.data,
        searchForUsersNextReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.SEARCH_FOR_USERS_NEXT_ERROR:
      return { ...state, searchForUsersNextReqStat: AsyncStatus.ERROR };

    // CREATE SINGLE CHAT
    case actionTypes.CREATE_SINGLE_CHAT_START:
      return { ...state, createSingleChatReqStat: AsyncStatus.LOADING };

    case actionTypes.CREATE_SINGLE_CHAT_SUCCESS:
      return {
        ...state,
        createdChatInfo: action.data,
        createSingleChatReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.CREATE_SINGLE_CHAT_ERROR:
      return { ...state, createSingleChatReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_SINGLE_CHAT_REQ_STAT:
      return {
        ...state,
        createSingleChatReqStat: AsyncStatus.NONE,
      };

    case actionTypes.CLEAR_CREATED_CHAT_INFO:
      return {
        ...state,
        createdChatInfo: null,
        createSingleChatReqStat: AsyncStatus.NONE,
      };

    // CREATE MULTIPLE CHAT
    case actionTypes.CREATE_MULTIPLE_CHAT_START:
      return { ...state, createMultipleChatReqStat: AsyncStatus.LOADING };

    case actionTypes.CREATE_MULTIPLE_CHAT_SUCCESS:
      return {
        ...state,
        createMultipleChatReqStat: AsyncStatus.SUCCESS,
        createdChatInfo: action.data,
      };

    case actionTypes.CREATE_MULTIPLE_CHAT_ERROR:
      return { ...state, createMultipleChatReqStat: AsyncStatus.ERROR };

    // MOVE CHAT TO TOP
    case actionTypes.MOVE_CHAT_TO_TOP: {
      const { index, chat } = action.data;
      const oldChats = state.chats;
      const newData = getChatsUpdated(state, index, chat);

      return {
        ...state,
        chats: {
          ...oldChats,
          data: newData,
        },
      };
    }

    // SET SELECTED CHAT ID
    case actionTypes.SET_SELECTED_CHAT_ID:
      return { ...state, selectedChatId: action.data };

    // INSERT NEW MESSAGE
    case actionTypes.INSERT_NEW_MESSAGE: {
      const chatId = action.data.to;

      const oldChats = state.chats;
      const oldChatsData = oldChats.data;
      let chatMessage = {};
      const index = oldChatsData.findIndex((element) => {
        chatMessage = element;
        return element._id === chatId;
      });
      const oldChat = state.createdChatInfo || state.roomInfo;

      chatMessage =
        index < 0
          ? { ...oldChat?.chat, isRead: true, notifications: true }
          : { ...chatMessage, isRead: action?.data?.isMe };

      chatMessage = Immutable.set(chatMessage, 'lastMessage', action.data);

      const newChatsData = getChatsUpdated(state, index, chatMessage);

      const chatIdObject = _.get(state, `messages.${chatId}`, {});
      const prevMessages = _.get(chatIdObject, 'messages', []);

      const newMessages = [...[action.data], ...prevMessages];
      const convertedMessages = convertMessages(newMessages);

      return {
        ...state,
        chats: { ...oldChats, data: newChatsData },
        messages: {
          ...state.messages,
          [chatId]: {
            ...chatIdObject,
            messages: newMessages,
            convertedMessages,
          },
        },
      };
    }

    // DELETE CHAT MESSAGE
    case actionTypes.DELETE_CHAT_MESSAGE: {
      const chatId = state.selectedChatId;

      const chatIdObject = _.get(state, `messages.${chatId}`, {});
      const newMessages = state.messages[chatId]?.messages.filter(
        (el) => el._id !== action.data
      );
      const newConvertedMessages = convertMessages(newMessages);

      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: {
            ...chatIdObject,
            messages: newMessages,
            convertedMessages: newConvertedMessages,
          },
        },
      };
    }

    case actionTypes.PIN_CHAT_MESSAGE: {
      const chatId = state.selectedChatId;
      const chatIdObject = _.get(state, `messages.${chatId}`, {});
      
      // Find the specific message by messageId
      const newMessages = state.messages[chatId]?.messages?.map((message) => {
        if (message._id === action.payload.messageId) {
          console.log('message._id==>',message._id);
          console.log('action.payload.messageId==>',action.payload.messageId);
          
          return {
            ...message,
            pin: !message.pin,  // Toggle the pin state instead of directly setting it
          };
        }
        return message; // Leave other messages unchanged
      });
    
      const newConvertedMessages = convertMessages(newMessages);
    
      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: {
            ...chatIdObject,
            messages: newMessages,
            convertedMessages: newConvertedMessages,
          },
        },
      };
    }
    
    
    

    // MARK MESSAGE AS SENT
    case actionTypes.MARK_MESSAGE_AS_SENT: {
      const chatId = state.selectedChatId;
      const chatIdObject = _.get(state, `messages.${chatId}`, {});

      const prevMessages = _.get(chatIdObject, 'messages', []);

      let originalMessage = _.get(prevMessages, `[0]`, {});
      originalMessage = { ...originalMessage, isLoading: false };
      const newPrevMessages = Immutable.set(prevMessages, 0, originalMessage);

      const prevConvertedMessages = _.get(
        chatIdObject,
        'convertedMessages',
        []
      );

      let convertedMessage = _.get(prevConvertedMessages, `[0]`, {});
      convertedMessage = { ...convertedMessage, isLoading: false };
      const newConvertedMessages = Immutable.set(
        prevConvertedMessages,
        0,
        convertedMessage
      );

      return {
        ...state,
        messages: {
          ...state.messages,
          [chatId]: {
            ...chatIdObject,
            messages: newPrevMessages,
            convertedMessages: newConvertedMessages,
          },
        },
      };
    }

    // CHANGE CHAT SETTINGS
    case actionTypes.CHANGE_CHAT_SETTINGS_START:
      return { ...state, changeChatSettingsReqStat: AsyncStatus.LOADING };

    case actionTypes.CHANGE_CHAT_SETTINGS_SUCCESS:
      return { ...state, changeChatSettingsReqStat: AsyncStatus.SUCCESS };

    case actionTypes.CHANGE_CHAT_SETTINGS_ERROR:
      return { ...state, changeChatSettingsReqStat: AsyncStatus.ERROR };

    // CLEAR CHAT MEMBERS
    case actionTypes.CLEAR_CHAT_MEMBERS:
      return { ...state, chatMembers: null };

    // DELETE CHAT
    case actionTypes.DELETE_CHAT_START:
      return { ...state, deleteChatReqStat: AsyncStatus.LOADING };

    case actionTypes.DELETE_CHAT_SUCCESS: {
      const newChatsData = state.chats.data.filter(
        (el) => el._id !== action.data
      );

      return {
        ...state,
        chats: { ...state.chats, data: newChatsData },
        deleteChatReqStat: AsyncStatus.SUCCESS,
      };
    }

    case actionTypes.DELETE_CHAT_ERROR:
      return { ...state, deleteChatReqStat: AsyncStatus.ERROR };

    // CLEAR CHATS DATA
    case actionTypes.CLEAR_CHATS_DATA:
      return { ...initialState };

    // CLEAR MESSAGES REQ STAT
    case actionTypes.CLEAR_MESSAGES_REQ_STAT:
      return { ...state, getMessagesReqStat: AsyncStatus.NONE };

    // CHANGE CHAT STATUS
    case actionTypes.CHANGE_CHAT_STATUS: {
      const { chatId, isRead } = action.data;
      const oldChats = state.chats;
      const oldChatsData = oldChats.data;
      const newChatsData = oldChatsData.map((chat) => {
        if (chat._id === chatId) {
          return { ...chat, isRead };
        }

        return chat;
      });

      return {
        ...state,
        chats: { ...oldChats, data: newChatsData },
      };
    }

    // GET ROOM INFO
    case actionTypes.GET_ROOM_INFO_START:
      return { ...state, getRoomInfoReqStat: AsyncStatus.LOADING };

    case actionTypes.GET_ROOM_INFO_SUCCESS:
      return {
        ...state,
        roomInfo: action.data,
        getRoomInfoReqStat: AsyncStatus.SUCCESS,
      };

    case actionTypes.GET_ROOM_INFO_ERROR:
      return { ...state, getRoomInfoReqStat: AsyncStatus.ERROR };

    case actionTypes.CLEAR_ROOM_INFO:
      return { ...state, roomInfo: null, getRoomInfoReqStat: AsyncStatus.NONE };

    // EDIT MULTIPLE CHAT
    case actionTypes.EDIT_MULTIPLE_CHAT_START:
      return { ...state, editMultipleChatReqStat: AsyncStatus.LOADING };

    case actionTypes.EDIT_MULTIPLE_CHAT_SUCCESS:
      return { ...state, editMultipleChatReqStat: AsyncStatus.SUCCESS };

    case actionTypes.EDIT_MULTIPLE_CHAT_ERROR:
      return { ...state, editMultipleChatReqStat: AsyncStatus.ERROR };

    default:
      return state;
  }
};

export default reducer;
