/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { PlusIcon } from 'public/assets/icons';
import { useOutsideClick } from 'utils/hooks';
import {
  openSideBarCreateLinkPost,
  openSideBarCreatePhotoPost,
  addTags,
} from 'store/sideBarControler/actions';
import { AddPostWrap, AddPostText, PostTypePopoverWrap } from './AddPost.style';
import { IAddPost } from './IAddPost';
import { ListUserUserActions } from '../Popover/Popover.style';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';

export const AddPost = ({ type, eventTag, groupTag }: IAddPost) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const appStateAction = appState();
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const refAddPostPopover = useRef(null);

  useOutsideClick(refAddPostPopover, () => {
    setIsPopoverOpen(false);
  });

  const onAddPostClick = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const onOpenPostSidebarClick = (postType: string) => {
    const tag = type === 'event' ? eventTag : groupTag;

    if (postType === 'photo') {
      dispatch(
        openSideBarCreatePhotoPost({
          postForEditData: {},
          defaultTagId: tag._id,
        })
      );
      appStateAction(APP_STATE.CREATE_PHOTO_POST_FORM, {
        isOpen: true,
        data: {
          events: [eventTag],
        }
      });
    } else {
      dispatch(openSideBarCreateLinkPost({ defaultTagId: tag._id }));
    }

    dispatch(addTags({ type: `${type}s`, tags: [tag] }));
  };

  return (
    <AddPostWrap ref={refAddPostPopover} onClick={onAddPostClick}>
      <AddPostText>{t('common:addAPost')}</AddPostText>
      <PlusIcon />

      {isPopoverOpen && (
        <PostTypePopoverWrap>
          <ListUserUserActions>
            <li onClick={() => onOpenPostSidebarClick('link')}>
              {t('event:linkPost')}
            </li>
            <li onClick={() => onOpenPostSidebarClick('photo')}>
              {t('event:photoPost')}
            </li>
          </ListUserUserActions>
        </PostTypePopoverWrap>
      )}
    </AddPostWrap>
  );
};
