/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format } from 'date-fns';
import { EventsIcon, TimeIcon } from 'public/assets/icons';
import { IDatePicker } from './ICustomDatePicker';
import { Wrapper, DateText, ItemWrap } from './CustomDatePicker.style';
import { getI18n, useTranslation } from 'react-i18next';
import 'react-datepicker/dist/react-datepicker.css';
import { es } from 'date-fns/locale';

registerLocale('es', es);
const CustomDatePicker = ({ selectedDate, onChange, minDate }: IDatePicker) => {
  const { t } = useTranslation();
  const lng = getI18n().language;

  const CustomInput = forwardRef((props: any, ref: any) => (
    <Wrapper ref={ref}>
      <ItemWrap mr={16} onClick={props.onClick}>
        <EventsIcon />
        <DateText>
          {lng == 'es'
            ? format(new Date(props.value), 'dd/MM/yyyy', { locale: es })
            : props.value}
        </DateText>
      </ItemWrap>
      <ItemWrap mr={16} onClick={props.onClick}>
        <TimeIcon />
        <DateText>
          {selectedDate
            ? format(selectedDate, lng == 'es' ? 'HH:mm' : 'p')
            : t('timePicker:selectTime')}
        </DateText>
      </ItemWrap>
    </Wrapper>
  ));

  return (
    <DatePicker
      selected={selectedDate}
      onChange={onChange}
      locale={lng === 'es' ? 'es' : ''}
      showTimeSelect
      customInput={<CustomInput />}
      minDate={minDate}
    />
  );
};

export default CustomDatePicker;
