/* eslint-disable no-unused-expressions */
import { useTheme } from 'styled-components';
import { useRouter } from 'next/router';
import API_NAME from 'services/api';
import { useDispatch } from 'react-redux';
import { fetchApiReq } from 'store/fetchApi/actions';

import {
  ChatIcon,
  HomeIcon,
  MapIcon,
  NotificationsIcon,
  SearchIcon,
} from 'public/assets/icons';
import { routesPath } from 'utils/constants/routePath';
import NavigationButton from 'components/common/NavigationButton';
import fetchApi from 'hooks/fetchApi';
import { useTranslation } from 'react-i18next';
import { INavigation } from './INavigation';

import { NavigationItemWrapper, NavigationWrapper } from './Navigation.styles';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const Navigation = ({
  onOpenChatList,
  onOpenNotification,
  isOpenSidebarNotifications,
  isOpenSidebarChatsList,
  isOpenSidebarChatConversation,
}: INavigation) => {
  const { feedPath, searchPath, mapPath } = routesPath;
  const theme: any = useTheme();
  const { action } = fetchApi();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const router = useRouter();
  const { route } = router;

  const handleRefreshFeedPage = () => {
    const currentUrl = router?.query?.t;
    if (currentUrl === 'following') {
      dispatch(
        fetchApiReq({
          key: API_NAME.FEED_GET_FOLLOWING_POSTS,
          res: {
            data: undefined,
            error: false,
            success: true,
            loading: true,
            state: true,
          },
        })
      );
      action(API_NAME.FEED_GET_FOLLOWING_POSTS, {
        params: {
          offset: 0,
          limit: 18,
          filterIds: '',
        },
      });
    }
    if (currentUrl === 'discover') {
      dispatch(
        fetchApiReq({
          key: API_NAME.FEED_GET_DISCOVER_POSTS,
          res: {
            data: undefined,
            error: false,
            success: true,
            loading: true,
            state: true,
          },
        })
      );
      action(API_NAME.FEED_GET_DISCOVER_POSTS, {
        params: {
          start: 0,
          limit: 18,
          filterIds: '',
        },
      });
    }
    if (currentUrl === 'events') {
      dispatch(
        fetchApiReq({
          key: API_NAME.FEED_GET_EVENT_POSTS,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.FEED_GET_EVENT_POSTS, {
        params: {
          offset: 0,
          limit: 20,
        },
      });
    }
    if (currentUrl === 'shop') {
      dispatch(
        fetchApiReq({
          key: API_NAME.FEED_GET_SHOP_POSTS,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.FEED_GET_SHOP_POSTS, {
        params: {
          offset: 0,
          limit: 20,
        },
      });
    }
    if (currentUrl === 'business') {
      dispatch(
        fetchApiReq({
          key: API_NAME.FEED_GET_BUSINESS_POSTS,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.FEED_GET_BUSINESS_POSTS, {
        params: {
          offset: 0,
          limit: 4,
        },
      });
    }
    if (
      currentUrl === 'following' ||
      currentUrl === 'discover' ||
      currentUrl === 'events' ||
      currentUrl === 'shop' ||
      currentUrl === 'business'
    )
      router.push(`${feedPath}?t=${currentUrl}`);
    else router.push(`${feedPath}?t=following`);
  };

  const handleRefreshSearchPage = () => {
    const currentUrl = router?.query?.t;
    if (currentUrl === 'profiles') {
      dispatch(
        fetchApiReq({
          key: API_NAME.SEARCH_PROFILE,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.SEARCH_PROFILE, {
        params: {
          offset: 0,
          limit: 21,
        },
      });
    }
    if (currentUrl === 'groups') {
      dispatch(
        fetchApiReq({
          key: API_NAME.SEARCH_GROUP,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.SEARCH_GROUP, {
        params: {
          offset: 0,
          limit: 21,
        },
      });
    }
    if (currentUrl === 'posts') {
      dispatch(
        fetchApiReq({
          key: API_NAME.SEARCH_POSTS,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.SEARCH_POSTS, {
        params: {
          start: 0,
          limit: 21,
        },
      });
    }
    if (currentUrl === 'vehicles') {
      dispatch(
        fetchApiReq({
          key: API_NAME.SEARCH_VEHICLES,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.SEARCH_VEHICLES, {
        params: {
          start: 0,
          limit: 21,
        },
      });
    }
    if (currentUrl === 'events') {
      dispatch(
        fetchApiReq({
          key: API_NAME.SEARCH_EVENTS,
          res: { data: undefined, error: false, success: true, loading: true },
        })
      );
      action(API_NAME.SEARCH_EVENTS, {
        params: {
          start: 0,
          limit: 21,
          filter: '',
        },
      });
    }
    if (
      currentUrl === 'profiles' ||
      currentUrl === 'groups' ||
      currentUrl === 'posts' ||
      currentUrl === 'vehicles' ||
      currentUrl === 'events'
    )
      router.push(`${searchPath}?t=${currentUrl}`);
    else router.push(`${searchPath}?t=profiles`);
  };

  const navigationData = [
    {
      id: 1,
      icon: (
        <HomeIcon
          colorFill={
            route === feedPath &&
            !isOpenSidebarNotifications &&
            !(isOpenSidebarChatsList || isOpenSidebarChatConversation)
              ? theme.colors.blue
              : 'none'
          }
          colorStroke={
            route === feedPath &&
            !isOpenSidebarNotifications &&
            !(isOpenSidebarChatsList || isOpenSidebarChatConversation)
              ? theme.colors.blue
              : theme.colors.black
          }
        />
      ),
      text: t('menu:feed'),
      onClick: () => handleRefreshFeedPage(),
      active:
        route === feedPath &&
        !isOpenSidebarNotifications &&
        !(isOpenSidebarChatsList || isOpenSidebarChatConversation),
    },
    {
      id: 2,
      icon: (
        <SearchIcon
          colorStroke={
            route === searchPath &&
            !isOpenSidebarNotifications &&
            !(isOpenSidebarChatsList || isOpenSidebarChatConversation)
              ? theme.colors.blue
              : theme.colors.black
          }
        />
      ),
      text: t('menu:search'),
      onClick: () => handleRefreshSearchPage(),
      active:
        route === searchPath &&
        !isOpenSidebarNotifications &&
        !(isOpenSidebarChatsList || isOpenSidebarChatConversation),
    },
    {
      id: 3,
      icon: (
        <NotificationsIcon
          colorStroke={
            isOpenSidebarNotifications ? theme.colors.blue : theme.colors.black
          }
        />
      ),
      text: t('menu:notifications'),
      onClick: () => onOpenNotification(),
      active: isOpenSidebarNotifications,
    },
    {
      id: 4,
      icon: (
        <MapIcon
          colorStroke={
            route === mapPath &&
            !isOpenSidebarNotifications &&
            !(isOpenSidebarChatsList || isOpenSidebarChatConversation)
              ? theme.colors.blue
              : theme.colors.black
          }
        />
      ),
      text: t('menu:map'),
      onClick: () => router.push(routesPath.mapPath),
      active:
        route === mapPath &&
        !isOpenSidebarNotifications &&
        !(isOpenSidebarChatsList || isOpenSidebarChatConversation),
    },
    {
      id: 5,
      icon: (
        <ChatIcon
          colorStroke={
            isOpenSidebarChatsList || isOpenSidebarChatConversation
              ? theme.colors.blue
              : theme.colors.black
          }
        />
      ),
      text: t('menu:chat'),
      onClick: () => onOpenChatList(),
      active: isOpenSidebarChatsList || isOpenSidebarChatConversation,
    },
  ];

  return (
    <NavigationWrapper>
      {navigationData?.map((navBtn) => (
        <NavigationItemWrapper key={navBtn.id} active={navBtn.active}>
          <NavigationButton
            icon={navBtn.icon}
            onClick={navBtn.onClick}
            text={navBtn.text}
            active={navBtn.active}
          />
        </NavigationItemWrapper>
      ))}
    </NavigationWrapper>
  );
};

export default Navigation;
