import { call, put, takeLatest } from 'redux-saga/effects';

import {
  deleteMember,
  getAllClubs,
  getMemberList,
  getSingleClub,
  postActiveClubInfo,
  postJoinClub,
  verifyClubId,
} from 'services';

import {
  actionTypes,
  getAllClubsSuccess,
  getAllClubsFailure,
  getSingleClubSuccess,
  getSingleClubFailure,
  getMemberListSuccess,
  getMemberListFailure,
  postActiveClubInfoSuccess,
  postActiveClubInfoFailure,
  postJoinClubSuccess,
  postJoinClubFailure,
  deleteMemberSuccess,
  deleteMemberFailure,
  verifyClubFailure,
  verifyClubSuccess,
} from './actions';
import Router from 'next/router';

function* workerGetAllClubs() {
  try {
    const resp = yield call(getAllClubs);
    yield put(getAllClubsSuccess(resp?.data));
  } catch (err) {
    yield put(getAllClubsFailure(err));
  }
}
function* workerGetSingleClub(action) {
  const { onSuccess } = action;
  try {
    const { clubId } = action.payload;
    const params = { clubId };
    const resp = yield call(getSingleClub, params);
    yield put(getSingleClubSuccess(resp?.data));
    onSuccess && onSuccess(resp?.data);
  } catch (err) {
    yield put(getSingleClubFailure(err));
  }
}
function* workerGetMemberList(action) {
  try {
    const { clubId } = action.payload;
    const params = { clubId };
    const resp = yield call(getMemberList, params);
    console.log('GET MEMBER LIST IS', resp);
    yield put(getMemberListSuccess(resp?.data));
  } catch (err) {
    yield put(getMemberListFailure(err));
  }
}
function* workerPostActiveClubInfo(action) {
  try {
    const { clubId } = action.payload;
    const activeClubId = clubId === 'RoadStr' ? null : clubId;
    const params = { clubId: activeClubId };
    const resp = yield call(postActiveClubInfo, params);
    yield put(postActiveClubInfoSuccess(resp?.data));
  } catch (err) {
    yield put(postActiveClubInfoFailure(err));
  }
}
function* workerPostJoinClub(action) {
  try {
    const { clubId, inviteToken, code, isFreeTrialExpired, onSuccess } = action.payload;
    const params = {
      clubId,
      inviteToken,
      code,
      isFreeTrialExpired
    };
    const resp = yield call(postJoinClub, params);
    const { data } = resp;
    yield put(postJoinClubSuccess(data));
    // eslint-disable-next-line no-unused-expressions
    onSuccess && onSuccess({ status: resp?.status, data: resp?.data });
  } catch (err) {
    yield put(postJoinClubFailure(err));
  }
}
function* workerDeleteMember(action) {
  try {
    const { clubId, memberId } = action.payload;
    const params = { clubId, memberId };
    const resp = yield call(deleteMember, params);
    console.log('DELETE MEMBER IS', resp);
    yield put(deleteMemberSuccess(resp?.data));
  } catch (err) {
    yield put(deleteMemberFailure(err));
  }
}

function* workerVerifyClubMember(action) {
  try {
    const { id, clubVerificationId, onSuccess } = action.payload;
    const params = { id, clubVerificationId };
    const response = yield call(verifyClubId, params);
    console.log('response', response);
    yield put(verifyClubSuccess(response));
    onSuccess && onSuccess(response);
  } catch (error) {
    yield put(verifyClubFailure(error));
  }
}

function* watchClub() {
  yield takeLatest(actionTypes.GET_ALL_CLUBS_REQUEST, workerGetAllClubs);
  yield takeLatest(actionTypes.GET_SINGLE_CLUB_REQUEST, workerGetSingleClub);
  yield takeLatest(actionTypes.GET_MEMBER_LIST_REQUEST, workerGetMemberList);
  yield takeLatest(
    actionTypes.POST_ACTIVE_CLUB_INFO_REQUEST,
    workerPostActiveClubInfo
  );
  yield takeLatest(actionTypes.POST_JOIN_CLUB_REQUEST, workerPostJoinClub);
  yield takeLatest(actionTypes.DELETE_MEMBER_REQUEST, workerDeleteMember);
  yield takeLatest(
    actionTypes.CLUB_VERIFICATION_REQUEST,
    workerVerifyClubMember
  );
}

export default watchClub;
