import { useEffect, useMemo, useState } from 'react';
import Drawer from 'components/Drawer';
import { useTranslation } from 'react-i18next';
import {
  SectionWrapper,
  SeparateSettingsHeader,
  SelectWrapper,
  SelectIconWrap,
  TimeZoneText,
  Select,
  MainContainer,
  ButtonContainer,
  HeaderDivider,
} from './RecurringEventDrawer.style';
import { getDateInOriginalTimezone } from 'utils/date';
import moment, { Moment } from 'moment';
import { mapTimezoneOffsetToMinutes } from 'utils/time';
import { Field } from 'react-final-form';
import { PlusIcon, WorldIcon } from 'public/assets/icons';
import ScheduleTime from 'components/ScheduleTime';
import momentTimezone from 'moment-timezone';
import { format } from 'date-fns';
import { minimalTimezoneSet } from 'node_modules/compact-timezone-list';
import { RectangleButton } from '../Buttons';
import { style } from 'utils/constants/style';

interface Props {
  open: boolean;
  setOpen: (value: boolean) => void;
  form?: any;
  initialValues?: any;
  handleOnChange?: (value: any) => void;
}

type timeSlots = {
  eventStart?: any;
  eventEnd?: any;
  timeZone?: any;
};

const RecurringEventDrawer = ({
  open,
  setOpen,
  form,
  initialValues,
  handleOnChange,
}: Props) => {
  const { t } = useTranslation();

  const [slots, setSlots] = useState<Array<timeSlots>>([]);

  // Populate slots from initialValues
  useEffect(() => {
    if (!initialValues) return;
    if (initialValues?.length) {
      const formattedSlots = initialValues?.map((slot: any) => ({
        eventStart: moment(slot?.eventStartDate), // Convert timestamp to moment
        eventEnd: moment(slot?.eventEndDate),
        timeZone: slot?.timeZone || format(new Date(), 'xxx'),
      }));
      setSlots(formattedSlots);
    }
  }, [initialValues]); // Runs when initialValues change

  const getTimezone = (index: number) => {
    const selectedSlot = slots[index];
    if (!selectedSlot) return '';

    const minimalTimeOne = minimalTimezoneSet.find(
      (d) => d.offset === selectedSlot.timeZone
    );

    if (!minimalTimeOne) return '';

    const { offset, tzCode, label } = minimalTimeOne;
    const tzAbbrMoment = moment.tz(tzCode).format('z');
    const isAbbrValid = !tzAbbrMoment.match(/[+-]/);

    return isAbbrValid ? `(GMT${offset}) ${tzAbbrMoment}` : label;
  };

  const closeDrawer = () => setOpen(false);

  const addNewSlot = () => {
    setSlots([
      ...slots,
      {
        eventStart: moment(),
        eventEnd: moment().add(1, 'hour'),
        timeZone: format(new Date(), 'xxx'),
      },
    ]);
  };

  const removeTimeSlot = (index: number) => {
    setSlots(slots.filter((_, i) => i !== index));
  };

  const updateSlot = (index: number, field: keyof timeSlots, value: any) => {
    setSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      updatedSlots[index] = { ...updatedSlots[index], [field]: value };
      return updatedSlots;
    });
  };

  const updateSlotTimezone = (index: number, offset: any) => {
    setSlots((prevSlots) => {
      const updatedSlots = [...prevSlots];
      const updatedStartMoment = moment(
        updatedSlots[index].eventStart
      ).utcOffset(mapTimezoneOffsetToMinutes(offset), true);
      const updatedEndMoment = moment(updatedSlots[index].eventEnd).utcOffset(
        mapTimezoneOffsetToMinutes(offset),
        true
      );
      updatedSlots[index] = {
        ...updatedSlots[index],
        eventStart: updatedStartMoment,
        eventEnd: updatedEndMoment,
        timeZone: offset,
      };
      return updatedSlots;
    });
  };

  // Convert slots to formatted values
  const formatFormValues = () => {
    return slots.map((slot) => ({
      eventStartDate: moment.isMoment(slot.eventStart)
        ? slot.eventStart.valueOf()
        : slot.eventStart,
      eventEndDate: moment.isMoment(slot.eventEnd)
        ? slot.eventEnd.valueOf()
        : slot.eventEnd,
      timeZone: slot.timeZone,
    }));
  };

  const handleSubmit = () => {
    console.log('formatFormValues()==>', formatFormValues());

    form.change('recurringSchedule', formatFormValues());
    handleOnChange(formatFormValues());
    closeDrawer();
  };

  return (
    <Drawer
      open={open}
      onDrawerClose={closeDrawer}
      headerText={t('event:recurringEvent')}
      onIconClick={closeDrawer}
      submitText={t('settings:submitBtn')}
      onSubmit={handleSubmit}
      disabled={slots.length === 0}
    >
      <MainContainer>
        <ButtonContainer>
          <RectangleButton
            text={t('event:addNewTimeSlot')}
            color={style.mainWhite.white}
            backgroundColor={style.mainYellow.yellow}
            onClick={addNewSlot}
          />
        </ButtonContainer>
        {slots.map((slot, index) => (
          <SectionWrapper pt={16.5} pb={16.5} key={index}>
            <HeaderDivider>
              <SeparateSettingsHeader type="h3" mb={6} mt={16}>
                {`${t('event:timeSlot')} ${index + 1}`}
              </SeparateSettingsHeader>
            </HeaderDivider>
            <Field name={`startDate_${index}`}>
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(slot.eventStart)}
                  headerText={t('event:eventStart')}
                  onScheduleTimeChange={(v) => {
                    const updatedMoment = moment(v).utcOffset(
                      mapTimezoneOffsetToMinutes(slot.timeZone),
                      true
                    );
                    updateSlot(index, 'eventStart', updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={new Date()}
                />
              )}
            </Field>

            <Field name={`endDate_${index}`}>
              {({ input }) => (
                <ScheduleTime
                  selectedDate={getDateInOriginalTimezone(slot.eventEnd)}
                  headerText={t('event:eventEnd')}
                  onScheduleTimeChange={(v) => {
                    const updatedMoment = moment(v).utcOffset(
                      mapTimezoneOffsetToMinutes(slot.timeZone),
                      true
                    );
                    updateSlot(index, 'eventEnd', updatedMoment);
                    input.onChange(updatedMoment.valueOf());
                  }}
                  minDate={slot.eventStart || new Date()}
                />
              )}
            </Field>

            <SeparateSettingsHeader mb={4} mt={16}>
              {t('timePicker:timezone')}
            </SeparateSettingsHeader>

            <Field name="timeZone">
              {() => (
                <SelectWrapper>
                  <SelectIconWrap>
                    <WorldIcon />
                  </SelectIconWrap>
                  <TimeZoneText>{getTimezone(index)}</TimeZoneText>
                  <Select
                    onChange={(e) => updateSlotTimezone(index, e.target.value)}
                    value={slot.timeZone}
                  >
                    {minimalTimezoneSet.map((el, idx) => (
                      <option value={el.offset} key={idx}>
                        {el.label}
                      </option>
                    ))}
                  </Select>
                </SelectWrapper>
              )}
            </Field>

            <br />
            <RectangleButton
              text={t('event:removeTimeSlot')}
              color={style.mainWhite.white}
              backgroundColor={style.mainYellow.yellow}
              onClick={() => removeTimeSlot(index)}
            />
          </SectionWrapper>
        ))}
      </MainContainer>
    </Drawer>
  );
};

export default RecurringEventDrawer;
