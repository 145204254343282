import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { StartLocationIcon, MoveIcon, CloseIcon } from 'pages/map/icons';
import {
  openSidebarChoseStartPoint,
  closeSidebarPlanRoute,
} from 'store/map/actions';
import { IStartPoint } from './IStartPoint';
import {
  Container,
  LeftSideWrap,
  RightSideWrap,
  IconWrap,
  Text,
  ClickableText,
  BurgerIconDescription,
} from './StartPoint.style';

const StartPoint = ({
  address,
  isEditable = true,
  onCloseClick = null,
  onDrag = null,
  onDrop = null,
  waypointIndex = 0,
  onAddressClick
}: IStartPoint) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { isOpenSidebarPlanRoute } = useSelector(
    (state) => state.map.sidebarsInfo
  );

  const onClick = () => {
    if (isOpenSidebarPlanRoute) {
      dispatch(closeSidebarPlanRoute());
    }

    dispatch(openSidebarChoseStartPoint());
  };

  return (
    <Container
      draggable
      onDragOver={(ev) => ev.preventDefault()}
      onDragStart={onDrag}
      onDrop={onDrop}
      id={String(waypointIndex)}
    >
      <LeftSideWrap>
        <IconWrap mr={8}>
          <StartLocationIcon />
        </IconWrap>

        {address ? (
          <Text onClick={()=>onAddressClick&&onAddressClick()}>{address}</Text>
        ) : (
          <ClickableText onClick={onClick}>
            {t('map:chooseTheStartPoint')}
          </ClickableText>
        )}
      </LeftSideWrap>

      {address && isEditable && (
        <RightSideWrap>
          <IconWrap mr={8} cursor="pointer">
            <MoveIcon />

            <BurgerIconDescription>
              {t('map:dragToReorder')}
            </BurgerIconDescription>
          </IconWrap>

          <IconWrap cursor="pointer" onClick={onCloseClick}>
            <CloseIcon />
          </IconWrap>
        </RightSideWrap>
      )}
    </Container>
  );
};

export default StartPoint;
