import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useSwitchAppClubAccount from 'utils/useSwitchAppClubAccount';
import {
  getAllClubsRequest,
  postActiveClubInfoRequest,
  setActiveAccountId,
} from 'store/club/actions';
import { clearClubIdFromStorage, getClubIdFromCookies } from './storageUtils';
import { useRouter } from 'next/router';

const ClubManager = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const activeAccountId = useSelector((state) => state.club.activeAccountId);
  const userDetails = useSelector((store) => store.user?.userDetails);
  const activeClub = useSelector((state) => state?.club?.activeClub);

  const anonymousUserId =
    userDetails?._id === process.env._ANONYMOUS_ID ? userDetails?._id : null;
  const userId =
    userDetails?._id && userDetails?._id !== process.env._ANONYMOUS_ID
      ? userDetails?._id
      : null;
  const tutorialIsActive = userDetails?.tutorialIsActive;
  const userActiveClub = userDetails?.activeClub;
  const { handleClubSwitch } = useSwitchAppClubAccount();
  const lastActiveAccountId = useRef(null);

  useEffect(() => {
    if (userId) {
      dispatch(getAllClubsRequest());
    }
  }, [userId]);

  //Special handling for anonyous user to force them from not using club credentials
  useEffect(() => {
    if (anonymousUserId) {
      dispatch(setActiveAccountId(null));
      dispatch(postActiveClubInfoRequest(null));
      clearClubIdFromStorage();
    }
  }, [anonymousUserId]);

  // Executes on application start
  useEffect(() => {
    if (!userId) {
      return;
    }
    // make sure by any chance user is not using clubId when tutorial Is active
    // All of these additional conditions are because of a quick fix needed to avoid all that messup that anonymous user has created in pro
    // with user cookies
    if (userId && tutorialIsActive) {
      dispatch(setActiveAccountId(null));
      dispatch(postActiveClubInfoRequest(null));
      clearClubIdFromStorage();
      return;
    }
    const storedAccountId = userActiveClub;
    dispatch(setActiveAccountId(storedAccountId));
    dispatch(postActiveClubInfoRequest(storedAccountId));
  }, [dispatch, userId, userActiveClub, tutorialIsActive]);

  useEffect(() => {
    if (
      !userId ||
      (tutorialIsActive && !activeAccountId) ||
      router?.pathname === '/joinClub'
    ) {
      return;
    }
    const storedActiveAccountId = getClubIdFromCookies();
    // handles switching between clubs or switching from roadstr to club mode automatically
    if (activeAccountId && storedActiveAccountId !== activeAccountId) {
      handleClubSwitch(activeAccountId);
      // handles case when we have a club selected on webapp but user switched to roadstr on mobile app or any other browser
    } else if (!userActiveClub && storedActiveAccountId) {
      handleClubSwitch('RoadStr');
      // handles case when we were in club mode but then switched to roadstr
    } else if (lastActiveAccountId.current && !activeAccountId) {
      handleClubSwitch('RoadStr');
    }
    lastActiveAccountId.current = activeAccountId;
  }, [activeAccountId, dispatch, handleClubSwitch, userId, tutorialIsActive]);

  return null;
};

export default ClubManager;
