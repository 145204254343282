import styled from 'styled-components';
import { Form as FinalForm } from 'react-final-form';

import Form from 'components/common/Form';
import Heading from 'components/common/Typography/Heading';
import Text from 'components/common/Text';
import DrawerForm from 'forms/DrawerForm';
import Input from 'components/common/Input';
import Pills from 'components/common/Pills';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fw?: number;
  fs?: number;
  mr?: number;
  isSelectedStep?: boolean;
  selected?: boolean;
  italic?: boolean;
  checked?: boolean;
  ref?: any;
}

export const FinalFormWrapper = styled(FinalForm)``;

export const FormWrapper = styled(DrawerForm)``;

export const SectionWrapper = styled.div<IProps>`
  position: relative;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};
  &:nth-child(3) {
    padding-top: 0;
  }
  :last-of-type {
    border: none;
  }
`;
export const EventSectionWrapper = styled.div<IProps>`
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};
  &:nth-child(3) {
    padding-top: 0;
  }
  :last-of-type {
    // border: none;
  }
`;

export const SectionHeader = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fontSize.f7};
  line-height: 130%;
  font-weight: ${(props) => props.theme.typography.fontWeight.semiBold};
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 13px;
`;

export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SelectWrapper = styled.div`
  position: relative;
  height: 44px;
  width: 100%;
  max-width: 260px;
`;

export const TimeZoneText = styled.div`
  height: 44px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  color: ${(props) => props.theme.colors.black};
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
`;

export const Select = styled.select`
  height: 44px;
  width: 100%;
  max-width: 260px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: transparent;
  color: transparent;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
`;

export const SelectIconWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 8px;
`;

export const EventTypesWrap = styled.div`
  width: 100%;
  position: flex;
  flex-direction: column;
`;

export const EventTypesHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const EventTypesHeaderText = styled(Text)`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${(props) => props.theme.colors.black};
`;

export const EventTypesList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const EventTypeItemWrap = styled.div<IProps>`
  height: 50px;
  padding: 8px;
  box-sizing: border-box;
  border-radius: 4px;
  background-color: ${({ selected, theme }) =>
    selected ? theme.colors.blue : 'transparent'};
  border: ${({ selected, theme }) =>
    selected
      ? `1px solid ${theme.colors.blue}`
      : `1px solid ${theme.colors.neutral.n7}`};
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin: 0 8px 8px 0;
`;

export const EventTypeText = styled(Text)<IProps>`
  font-size: 11px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: 500;
  color: ${({ selected, theme }) =>
    selected ? theme.colors.neutral.n1 : theme.colors.spunPearl};
  margin-top: 2px;
`;

export const LocationText = styled(Text)`
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.black};
  margin: 14px 0 11px 8px;
`;

export const BrandsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const CollaboratorsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const InputsContainers = styled.div`
  & > div {
    &:first-child {
      margin: 22px 0 17px 0;
    }
    &:nth-child(2) {
      margin: 0 0 17px 0;
    }
    &:nth-child(3) {
      margin: 0 0 17px 0;
    }
    & > label {
      margin-bottom: 6px;
    }
    & > input {
      height: 44px;
      padding: 0 16px 0 36px;
    }
  }
`;

export const StepNumberWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const StepNumber = styled.div<IProps>`
  height: 4px;
  width: 24px;
  border-radius: 4px;
  margin-right: ${({ mr }) => (mr ? `${mr}px` : '0')};
  background-color: ${({ isSelectedStep, theme }) =>
    isSelectedStep ? theme.colors.orange : theme.colors.neutral.n4};
`;

export const AttendanceWrapper = styled.div`
  cursor: pointer;
  padding-bottom: 10px;
`;
export const RegistrationWrapper = styled.div`
  cursor: pointer;
  margin-top: 20px;
`;
export const RegistrationBlock = styled.div`
  display: flex;
  cursor: pointer;
`;
export const EventOwnerWrapper = styled.div`
  cursor: pointer;
`;
export const AttendanceWrapperLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items-center
`;

export const AttendanceBlock = styled.div`
  display: flex;
  padding-bottom: 30px;
  cursor: pointer;
`;
export const EventOwnerBlock = styled.div`
  display: flex;
  padding-bottom: 30px;
  cursor: pointer;
`;
export const AttendanceBlockText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  color: ${(props) => props.theme.colors.neutral.n11};
`;
export const AttendanceBlockLeft = styled.div``;
export const AttendanceBlockRight = styled.div``;

export const SelectedItemWrapper = styled.div``;
export const SelectedItemText = styled(Text)`
  padding: 10px 10px 10px 16px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.neutral.n7};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;
export const SelectedValues = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px 16px 8px;
`;

export const ItemForSelectWrapper = styled.div``;
export const ItemForSelectText = styled(Text)`
  padding: 10px 10px 10px 16px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.neutral.n7};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
`;

export const ItemForSelectValue = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
`;

export const RenderPills = styled(Pills)`
  padding: 0 8px 0;
`;
export const FormRenderPills = styled(Pills)`
  padding: 0 6px 0;
`;

export const SelectedPills = styled(Pills)`
  padding: 0 8px 0;
  & > span {
    &:last-child {
      & > svg {
        & > path {
          stroke: ${(props) => props.theme.colors.white};
        }
      }
    }
  }
`;

export const NoResultWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 70px;
`;

export const NoResultText = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n7};
  text-align: center;
  margin-top: 0;
  margin-bottom: 42px;
`;

export const FormSearchWrapper = styled.div`
  padding: 20px;
  & > div {
    & > div {
    }
  }
`;
export const FormSearch = styled(Input)`
  padding: 0;
  padding-left: 35px;
  height: 44px;
`;

export const SelectorWrapper = styled.div`
  margin-top: 21px;
`;

export const SelectedItemsWrap = styled.div`
  height: 118px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  overflow: auto;

  &::-webkit-scrollbar-track {
    background-color: ${(props) => props.theme.colors.neutral.n0};
  }

  &::-webkit-scrollbar {
    background-color: ${(props) => props.theme.colors.neutral.n0};
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
  }
`;

export const NoSelectedItemWrap = styled.div`
  width: 100%;
  height: 118px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  margin-bottom: 20px;
`;

export const NoSelectedItemText = styled(Text)<IProps>`
  font-size: 13px;
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n7};
  font-style: ${({ italic }) => (italic ? 'italic' : 'initial')};
  text-align: center;
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;

export const SelectedItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 16px;
`;
export const ItemImageWrap = styled.div`
  display: flex;
  margin-bottom: 4px;
  & > span {
    border-radius: 50%;
  }
`;
export const ItemName = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  width: 100%;
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
`;

export const SelectVehicleWrapper = styled.div`
  padding-top: 10px;
`;

export const RoutesContentWrap = styled.div`
  padding: 20px;
`;

export const RouteWrap = styled.div`
  width: 100%;
  padding: 10.5px 0 16px 0;
  box-sizing: border-box;
  margin-bottom: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.n5};
  cursor: pointer;
  position: relative;
`;

export const RouteName = styled(Heading)`
  font-size: ${(props) => props.theme.typography.fontSize.f5};
  line-height: 100%;
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-bottom: 10.5px;
`;

export const WaypointWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const WaypointName = styled(Text)`
  font-size: ${(props) => props.theme.typography.fontSize.f4};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  line-height: 16px;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-left: 6px;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const CheckBoxSpan = styled.span`
  position: absolute;
  right: 0;
  top: 12%;
`;

export const VehiclesWrap = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  @media only screen and (max-width: 800px) {
    justify-content: center;
  }
`;

export const ProfileButton = styled.span`
  display: flex;
  align-items: center;
  width: 200px;
  & > span {
    border-radius: 50%;
    &:nth-child(2) {
      margin: 0 8px;
    }
  }
`;

export const IconWrapper = styled.span`
  display: flex;
`;

export const DropdownWrapper = styled.div`
  z-index: 1001;
  position: relative;

  & > div {
    & > div {
      right: -100px;
      & > ul {
        right: -40px;
        top: 175px; // Move list slightly up
      }
    }
  }
`;

export const TextWrapper = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  color: ${(props) => props.theme.colors.text};
  line-height: 100%;
  letter-spacing: 0.02em;
  margin-horizontal: 10px;
  text-align: left;
`;

export const UserName = styled(Text)`
  color: ${(props) => props.theme.colors.neutral.n12};
  font-size: ${(props) => props.theme.typography.fontSize.f4};
  letter-spacing: 0.5px;
`;

export const FullName = styled(Text)`
  color: ${(props) => props.theme.colors.orange};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  letter-spacing: 0.5px;
  margin: 5px 0 6px;
`;
export const DesktopLoginHeaderRight = styled.div`
  display: flex;

  & > div {
    display: flex;
    & > button {
      position: relative;
      z-index: 120;
    }
    &:not(:last-child) {
      margin-right: 10px;
    }

    &:first-child {
      & > div {
        right: auto;
      }
    }
  }
`;

export const ItemWrap = styled.div<IProps>`
  display: flex;
  cursor: pointer;
  margin-bottom: 5px;
  text-overflow: ellipsis;
`;

export const ImageWrapper = styled.span`
  height: 40px;
  width: 40px;
  overflow: hidden;
  border-radius: 50%;
  margin-left: 15px;
`;
export const ItemDescriptions = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 9px;
  white-space: nowrap;
`;

export const ListItemName = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 100%;
  font-size: ${(props) => props.theme.typography.fontSize.f3};
  font-weight: ${(props) => props.theme.typography.fontWeight.medium};
  padding-bottom: 4px;
`;

export const ItemDescription = styled.span`
  font-family: ${(props) => props.theme.typography.fontFamily.primary};
  font-weight: ${(props) => props.theme.typography.fontWeight.regular};
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  line-height: 100%;
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.neutral.n8};
  margin-bottom: 4px;
`;

export const EmptyUserPhoto = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #e8f3ff;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CheckIcon = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  background-color: ${({ checked }) => (checked ? '#1a85ff' : 'transparent')};
  border-radius: 50%;
  margin-right: 10px;
`;

export const TextContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const PostCreatorListWrapper = styled.div`
  margin-top: 30px;
`;
export const PostCreatorList = styled.div`
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-bottom: 1px solid ${(props) => props.theme.colors.neutral.n4};
  &::-webkit-scrollbar-track {
    background: none;
  }

  &::-webkit-scrollbar {
    width: 4px;
    height: 100px;
    background: none;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${(props) => props.theme.colors.neutral.n6};
    border-radius: 10px;
  }
`;
export const PlaceHolderWrapper = styled.div`
  width: 140px;
  margin: 0 auto;
  padding: 20px 0;

  div {
    text-align: center;
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 120%;
    text-align: center;
    letter-spacing: 0.02em;
    color: ${(props) => props.theme.colors.neutral.n6};
  }

  img {
    background: none;
  }
`;
export const HeaderIconWrapper = styled.div<IProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  & > button {
    color: ${(props) => props.theme.colors.orange} !important;
    font-size: ${(props) => props.theme.typography.fontSize.f2};
    line-height: 12px;
    background: ${(props) => props.theme.colors.orangeLight} !important;
    border-radius: 30px;
  }
`;
export const CompleteFormStatusWrapper = styled.div<IProps>`
  background: ${(props) => props.theme.colors.orange} !important;
  padding: 10px;
  border-radius: 100px;
  display: flex;
  align-items: center;
`;
export const CompleteFormStatusText = styled.span<IProps>`
  font-size: ${(props) => props.theme.typography.fontSize.f2};
  line-height: 12px;
  color: ${(props) => props.theme.colors.white} !important;
`;
export const Row = styled.div<IProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  padding:15px;
  position: sticky;
  top: 0;
  z-index: 100;
  background-color:white;
`;
export const InputSearch = styled.div`
  width: 100%;
  input[type='text'] {
    background-color: #fff;
    box-shadow: 0px 1px 10px #ddd;
    &::placeholder {
      color: #a7a7a7;
      font-size: 15px;
    }
  }
  svg {
    width: 18px;
    path,
    circle {
      stroke: #a7a7a7;
    }
  }
`;
