import React from 'react';

import { anonymousClickHandler } from 'utils/anonymousClickHandler';
import { IMessageText } from './IMessageText';
import { Text, LinkText } from './MessageText.style';

export const MessageText = ({
  text,
  onHashtagClick,
  onMentionClick,
  linkColor,
}: IMessageText) => {
  const hashtagMentionFormatter = (string) =>
    string.split('\n').map((line, lineIndex) => (
      <React.Fragment key={lineIndex}>
        {line
          .split(' ')
          .filter(Boolean)
          .map((v, i) => {
            if (v.startsWith('#')) {
              return (
                <Text
                  onClick={() => anonymousClickHandler(() => onHashtagClick(v))}
                  key={i + v}
                  linkColor={linkColor}
                >
                  {v}
                </Text>
              );
            }
            if (v.startsWith('@')) {
              return (
                <Text
                  onClick={() => anonymousClickHandler(() => onMentionClick(v))}
                  key={i + v}
                  linkColor={linkColor}
                >
                  {v}
                </Text>
              );
            }
            if (v.startsWith('https') || v.startsWith('www')) {
              return (
                <LinkText
                  linkColor={linkColor}
                  target="_blank"
                  href={v}
                  key={i + v}
                >
                  {v}
                </LinkText>
              );
            }
            return `${v} `;
          })}
        <br />
      </React.Fragment>
    ));
  const formatedText = hashtagMentionFormatter(text);

  return formatedText;
};
