import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const inviteUserToEvent = (eventId, data) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOSTv12}api/v12/event/${eventId}/invite`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(data),
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getInvitedUsers = (eventId) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}/invited`, {
    method: 'GET',
    headers: { ...headers, tsec: JSON.parse(token) },
  }).then((response) => response.json());
};
