/* eslint-disable no-empty */
import React, { useEffect, useState, useContext, useMemo } from 'react';
import { useSelector, useDispatch, batch } from 'react-redux';
import { useRouter } from 'next/router';
import { MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import ExpandImage from 'components/ExpandImage';
import { ThemeContext } from 'theme';
import {
  addCommentPost,
  addCommentVehicle,
  getCommentsPost,
  getCommentsEvent,
  addCommentEvent,
  getCommentsVehicles,
  deleteComment,
} from 'services/comments';
import { routesPath } from 'utils/constants/routePath';
import {
  getPopularPostsStart,
  setIsScheduledPostCreated,
} from 'store/posts/actions';
import { getSideMyEventsStart, getSideGroupsStart } from 'store/user/actions';
import { setCurrentScreen } from 'utils/chatSocketManager';
import {
  getSessionStorage,
  setSessionStorage,
  getLocalStorage,
} from 'utils/hooks';
import { ConfirmationModal, RedirectLoading } from 'components';
import CreatePhotoPostDrawer from 'containers/CreatePhotoPost';
import CreateLinkPostDrawer from 'containers/CreateLinkPost';
import CreateEventDrawer from 'containers/CreateEvent';
// import Header from '../Header/Header';
import Header from 'containers/Header';
import PagesSkeletonModal from 'components/PagesSkeletonModal';
import EventCardSkeleton from 'components/EventCardSkeleton';
import ProfileCardSkeleton from 'components/ProfileCardSkeleton';
import BusinessMainCardSkeleton from 'components/BusinessMainCardSkeleton';
import ProductMainCardSkeleton from 'components/ProductMainCardSkeleton';
import ChooseLocationDrawer from 'containers/ChooseLocationDrawer';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import PostSkeleton from 'components/PostSkeleton/PostSkeleton';
import CommentsDrawer from 'containers/CommentsDrawer';
import LikesDrawer from 'containers/LIkesDrawer';
import VehicleDrawer from 'containers/VehicleDrawer';
import AddVehicleGroupDrawer from 'containers/AddVehicleGroupDrawer';
import CreateGroupDrawer from 'containers/CreateGroupDrawer';
import DiscussionDrawer from 'containers/DiscussionDrawer';
import { ILayout } from './ILayout';
import SideBar from '../SideBar/SideBar';
import {
  CreateLinkPost,
  EditProfile,
  CreatePhotoPost,
  AddVehicle,
  Tag,
  CreateEvent,
  Comments,
  Notifications,
  PendingApprovals,
  InviteToGroup,
  ChatsList,
  ChatConversation,
  NewChat,
  NewGroupChat,
  CreateGroup,
  ChooseLocation,
  Suggestions,
  Likes,
  InvolvedUsers,
  InviteToEvent,
  AddVehicleToGroup,
} from '../SideBarContent';
import TagWithAdding from '../SideBarContent/Tag/TagWithAdding';
import { AppAlert } from '..';
import AttendEvent from '../SideBarContent/AttendEvent/AttendEvent';
import AttendanceView from 'components/SideBarContent/AttendanceView/AttendanceView';

const Layout: React.FC<ILayout> = ({ token, children }: ILayout) => {
  const sideBarControler = useSelector((state) => state.sideBarControler);
  const { isScheduledPostCreated } = useSelector((state) => state.posts);
  const appStateAction = appState();
  const [comments, setComments] = useState(null);
  const [showAppAlert, setShowAppAlert] = useState(false);
  const [isRedirectLoading, setIsRedirectLoading] = useState(false);
  const appStateStore = useSelector((store) => store.app);
  const themeContext: any = useContext(ThemeContext);
  const router = useRouter();
  const { t } = useTranslation();
  const isAppAlertDisabled = getSessionStorage('app_alert');
  const userData = getLocalStorage('user_data');
  const {
    isOpenSideBarCreateLinkPost,
    isOpenSideBarEditProfile,
    isOpenSideBarCreatePhotoPost,
    isOpenSideBarTag,
    tagType,
    isOpenSideBarAddVehicle,
    isOpenSideBarAddVehicleToGroup,
    isOpenSidebarCreateEvent,
    isOpenSidebarComments,
    scrollToTop,
    isOpenSideBarTagWithAdding,
    commentsPostId,
    commentsType,
    isOpenSidebarNotifications,
    isOpenSidebarPendingApprovals,
    isOpenSidebarInviteToGroupe,
    isOpenSidebarAttendEvent,
    isOpenSidebarInviteEvent,
    isOpenSidebarEventAttendanceView,
    attendenceViewEventId,
    attendedEventId,
    inviteEventId,
    isOpenSidebarChatsList,
    isOpenSidebarChatConversation,
    isOpenSidebarNewChat,
    isOpenSidebarNewMultipleChat,
    selectedUsers,
    chatData,
    eventData,
    chatId,
    isOpenSidebarCreateGroup,
    sidebarHeaderStartText,
    groupForEditData,
    eventForEditData,
    postForEditData,
    defaultTagId,
    isOpenSidebarChooseLocation,
    isOpenSidebarSuggestions,
    isOpenSidebarLikes,
    likesInfo,
    isOpenSidebarInvolvedUsers,
    involvedUsersInfo,
  } = sideBarControler;
  const dispatch = useDispatch();
  const addComment = (e) => {
    if (commentsType === 'event') {
      return addCommentEvent({ eventId: commentsPostId, comment: e }).then(
        (resp) => {
          getCommentsEvent(commentsPostId).then((res) => setComments(res.data));
        }
      );
    }
    if (commentsType === 'car') {
      return addCommentVehicle({ vehicleId: commentsPostId, comment: e }).then(
        (resp) => {
          getCommentsVehicles(commentsPostId).then((res) =>
            setComments(res.data)
          );
        }
      );
    }
    return addCommentPost(commentsPostId, e).then((resp) => {
      getCommentsPost(commentsPostId).then((res) => setComments(res.data));
    });
  };
  const onDeleteComment = (commentId) => {
    if (commentsType === 'event') {
      return deleteComment({ commentId }).then((resp) => {
        getCommentsEvent(commentsPostId).then((res) => setComments(res.data));
      });
    }
    if (commentsType === 'car') {
      return deleteComment({ commentId }).then((resp) => {
        getCommentsVehicles(commentsPostId).then((res) =>
          setComments(res.data)
        );
      });
    }
    if (commentsType === 'profile') {
      return deleteComment({ commentId }).then((resp) => {
        getCommentsPost(commentsPostId).then((res) => setComments(res.data));
      });
    }
  };
  useEffect(() => {
    if (isAppAlertDisabled) {
      setShowAppAlert(false);
    } else {
      setShowAppAlert(true);
    }
  }, [isAppAlertDisabled]);
  useEffect(() => {
    if (isOpenSidebarChatConversation) {
      setCurrentScreen('chat');
    } else if (isOpenSidebarChatsList) {
      setCurrentScreen('chatsList');
    } else {
      setCurrentScreen('');
    }
  }, [isOpenSidebarChatsList, isOpenSidebarChatConversation]);
  useEffect(() => {
    if (commentsPostId) {
      try {
        if (commentsType === 'event') {
          getCommentsEvent(commentsPostId).then((resp) => {
            setComments(resp.data);
          });
        } else if (commentsType === 'car') {
          getCommentsVehicles(commentsPostId).then((resp) => {
            setComments(resp.data);
          });
        } else {
          getCommentsPost(commentsPostId).then((resp) =>
            setComments(resp.data)
          );
        }
      } catch (error) {}
    }
  }, [commentsPostId]);
  useEffect(() => {
    try {
      if (themeContext?.isAuthenticate) {
        if (token && token !== 'undefined') {
          batch(() => {
            dispatch(getPopularPostsStart());
            dispatch(getSideMyEventsStart({ offset: 0, limit: 10 }));
            dispatch(getSideGroupsStart({ offset: 0, limit: 10 }));
          });
        }
      }
    } catch (error) {}
  }, [token]);
  useEffect(() => {
    router.events.on('routeChangeStart', (url) => {
      if (url?.includes('/search')) return;
      appStateAction(APP_STATE.PAGE_LOADING, {
        isOpen: true,
        url,
      });
      const urlsForLoading = [
        // '/e',
        // '/u',
        // '/p',
        // '/v',
        // '/g',
        '/vehicles_attending',
        '/group_garage',
        // '/authentication',
      ];
      for (let i = 0; i < urlsForLoading.length; i += 1) {
        if (url.includes(urlsForLoading[i])) {
          setIsRedirectLoading(true);
          break;
        }
      }
    });
    router.events.on('routeChangeComplete', (url) => {
      appStateAction(APP_STATE.PAGE_LOADING, {
        isOpen: false,
        url: undefined,
      });
      if (typeof window !== 'undefined') {
        document.documentElement.style.scrollBehavior = 'auto';
        window.scrollTo(0, 0);
      }
      if (url !== '/feed' && url !== '/search' && url !== '/my_events') {
        setIsRedirectLoading(false);
      }
    });
    setIsRedirectLoading(false);
  }, []);
  const onAppAlertCrossClick = () => {
    setSessionStorage('app_alert', true);
    setShowAppAlert(false);
  };
  const onCloseScheduledPostModalClick = () => {
    dispatch(setIsScheduledPostCreated(false));
  };
  // useEffect(() => {
  //   router.events.on('routeChangeStart', (url) => {
  //     console.log('sefesfsfsessrfv', url);
  //   });
  //   // router.events.on('routeChangeComplete', (url) => {
  //   //   console.log('sefesfsfsessrfv 2', url);
  //   // });
  // }, []);

  const pageSkeleton = useMemo(() => {
    if (!appStateStore[APP_STATE.PAGE_LOADING]?.isOpen) {
      return;
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/e')) {
      return (
        <PagesSkeletonModal open>
          <EventCardSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/u')) {
      return (
        <PagesSkeletonModal open>
          <ProfileCardSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/g')) {
      return (
        <PagesSkeletonModal open>
          <ProfileCardSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/p')) {
      return (
        <PagesSkeletonModal open>
          <PostSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/v')) {
      return (
        <PagesSkeletonModal open>
          <PostSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/v')) {
      return (
        <PagesSkeletonModal open>
          <PostSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/s')) {
      return (
        <PagesSkeletonModal open>
          <ProductMainCardSkeleton />
        </PagesSkeletonModal>
      );
    }
    if (appStateStore[APP_STATE.PAGE_LOADING]?.url?.includes('/b')) {
      return (
        <PagesSkeletonModal open>
          <BusinessMainCardSkeleton />
        </PagesSkeletonModal>
      );
    }
  }, [appStateStore[APP_STATE.PAGE_LOADING]?.isOpen]);

  return (
    <>
      <div>
        {!isRedirectLoading && router.pathname !== '/' && <Header />}
        {isScheduledPostCreated && (
          <ConfirmationModal
            headerText={t('common:yourPostWasSuccessfullyScheduled')}
            submitText={t('map:ok')}
            onSubmit={onCloseScheduledPostModalClick}
            onCancelClick={onCloseScheduledPostModalClick}
            onlySubmitButton
          />
        )}
        {isOpenSideBarCreateLinkPost && (
          <SideBar>
            <CreateLinkPost
              postForEditData={postForEditData}
              defaultTagId={defaultTagId}
              privateFeatures={JSON.parse(userData).data.privateFeatures}
            />
          </SideBar>
        )}
        {appStateStore[APP_STATE.CREATE_LINK_POST_FORM]?.isOpen && (
          <CreateLinkPostDrawer />
        )}
        {isOpenSideBarEditProfile && (
          <SideBar>
            <EditProfile />
          </SideBar>
        )}
        {/* {appStateStore[APP_STATE.CREATE_PHOTO_POST_FORM]?.isOpen && (
          // <SideBar>
          //   <CreatePhotoPost
          //     postForEditData={postForEditData}
          //     defaultTagId={defaultTagId}
          //     privateFeatures={JSON.parse(userData).data.privateFeatures}
          //   />
          // </SideBar>
          <CreatePhotoPostDrawer />
        )} */}
        <CreatePhotoPostDrawer />

        {appStateStore[APP_STATE.CREATE_GROUP_DRAWER]?.isOpen && (
          <CreateGroupDrawer />
        )}
        {isOpenSidebarCreateGroup && (
          <SideBar>
            <CreateGroup groupForEditData={groupForEditData} />
          </SideBar>
        )}
        {/* {isOpenSideBarAddVehicle && (
          <SideBar>
            <AddVehicle />
          </SideBar>
        )} */}
        {appStateStore[APP_STATE.ADD_VEHICLE_FORM]?.isOpen && <VehicleDrawer />}

        {appStateStore[APP_STATE.ADD_VEHICLE_DRAWER]?.isOpen && (
          <AddVehicleGroupDrawer
            open={appStateStore[APP_STATE.ADD_VEHICLE_DRAWER]?.isOpen}
            groupId={appStateStore[APP_STATE.ADD_VEHICLE_DRAWER]?.id}
          />
        )}

        {isOpenSideBarAddVehicleToGroup && (
          <SideBar>
            <AddVehicleToGroup />
          </SideBar>
        )}
        {appStateStore[APP_STATE.EVENT_CREATE_FORM]?.isOpen && (
          // <SideBar scrollToTop={scrollToTop}>
          //   <CreateEvent eventForEditData={eventForEditData} />
          // </SideBar>
          <CreateEventDrawer />
        )}
        {isOpenSideBarTag && (
          <SideBar>
            <Tag
              type={tagType}
              sidebarHeaderStartText={sidebarHeaderStartText}
            />
          </SideBar>
        )}
        {isOpenSideBarTagWithAdding && (
          <SideBar>
            <TagWithAdding
              type={tagType}
              sidebarHeaderStartText={sidebarHeaderStartText}
            />
          </SideBar>
        )}
        {isOpenSideBarCreateLinkPost && (
          <SideBar>
            <CreateLinkPost
              postForEditData={postForEditData}
              defaultTagId={defaultTagId}
              privateFeatures={JSON.parse(userData).data.privateFeatures}
            />
          </SideBar>
        )}
        {isOpenSideBarEditProfile && (
          <SideBar>
            <EditProfile />
          </SideBar>
        )}
        {/* {isOpenSideBarCreatePhotoPost && (
          // <SideBar>
          //   <CreatePhotoPost
          //     postForEditData={postForEditData}
          //     defaultTagId={defaultTagId}
          //     privateFeatures={JSON.parse(userData).data.privateFeatures}
          //   />
          // </SideBar>
          // <CreatePhotoPostDrawer open={isOpenSideBarCreatePhotoPost} />
        )} */}
        {isOpenSidebarCreateGroup && (
          <SideBar>
            <CreateGroup groupForEditData={groupForEditData} />
          </SideBar>
        )}
        {isOpenSideBarAddVehicle && (
          <SideBar>
            <AddVehicle />
          </SideBar>
        )}
        <DiscussionDrawer />
        {isOpenSideBarAddVehicleToGroup && (
          <SideBar>
            <AddVehicleToGroup />
          </SideBar>
        )}
        {/* {isOpenSidebarCreateEvent && eventForEditData && (
          // <SideBar scrollToTop={scrollToTop}>
          //   <CreateEvent eventForEditData={eventForEditData} />
          // </SideBar>
          <CreateEventDrawer
            open={isOpenSidebarCreateEvent}
            data={eventForEditData}
          />
        )} */}
        {isOpenSideBarTag && (
          <SideBar>
            <Tag
              type={tagType}
              sidebarHeaderStartText={sidebarHeaderStartText}
            />
          </SideBar>
        )}
        {isOpenSideBarTagWithAdding && (
          <SideBar>
            <TagWithAdding
              type={tagType}
              sidebarHeaderStartText={sidebarHeaderStartText}
            />
          </SideBar>
        )}
        {isOpenSidebarComments && (
          <SideBar>
            <Comments
              comments={comments}
              type={commentsType}
              onAddComment={(e) => addComment(e)}
              onDeleteComment={onDeleteComment}
            />
          </SideBar>
          // <CommentsDrawer open={isOpenSidebarComments} />
        )}
        {isOpenSidebarNotifications && (
          <SideBar>
            <Notifications />
          </SideBar>
        )}
        {isOpenSidebarPendingApprovals && (
          <SideBar>
            <PendingApprovals />
          </SideBar>
        )}
        {isOpenSidebarInviteToGroupe && (
          <SideBar>
            <InviteToGroup />
          </SideBar>
        )}
        {isOpenSidebarAttendEvent && (
          <SideBar>
            <AttendEvent attendId={attendedEventId} />
          </SideBar>
        )}
        {isOpenSidebarInviteEvent && (
          <SideBar>
            <InviteToEvent eventId={inviteEventId} />
          </SideBar>
        )}
        {isOpenSidebarEventAttendanceView && (
          <SideBar>
            <AttendanceView eventId={attendenceViewEventId} eventData={eventData} />
          </SideBar>
        )}
        {isOpenSidebarChatsList && (
          <SideBar>
            <ChatsList />
          </SideBar>
        )}
        {isOpenSidebarChatConversation && (
          <SideBar>
            <ChatConversation chatData={chatData} />
          </SideBar>
        )}
        {isOpenSidebarNewChat && (
          <SideBar>
            <NewChat />
          </SideBar>
        )}
        {isOpenSidebarNewMultipleChat && (
          <SideBar>
            <NewGroupChat selectedUsers={selectedUsers} chatId={chatId} />
          </SideBar>
        )}
        {isOpenSidebarChooseLocation && (
          <SideBar>
            <ChooseLocation />
          </SideBar>
        )}
        {isOpenSidebarSuggestions && (
          <SideBar>
            <Suggestions />
          </SideBar>
        )}
        {/* {isOpenSidebarLikes && (
          <SideBar>
            <Likes postId={likesInfo.postId} postType={likesInfo.postType} />
          </SideBar>
        )} */}
        {appStateStore[APP_STATE.LIKES_DRAWER_ACTION]?.isOpen && (
          <LikesDrawer
            postId={appStateStore[APP_STATE.LIKES_DRAWER_ACTION]?.id}
            postType={appStateStore[APP_STATE.LIKES_DRAWER_ACTION]?.postType}
          />
        )}
        {isOpenSidebarInvolvedUsers && (
          <SideBar>
            <InvolvedUsers
              involvedUserType={involvedUsersInfo.involvedUserType}
              id={involvedUsersInfo.id}
              isAdmin={involvedUsersInfo.isAdmin}
              myId={JSON.parse(userData).data._id}
            />
          </SideBar>
        )}
        {isRedirectLoading && <RedirectLoading />}
        {children}
      </div>
      <ExpandImage />
      {pageSkeleton}
    </>
  );
};
export default Layout;
