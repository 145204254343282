import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const getAllClubs = () => {
  const apiToken = localStorage.getItem('token');
  return fetchData(`${process.env._CLUB_BASE_API_URL}api/v01/user/club/`, {
    method: 'GET',
    headers: {
      ...headers,
      tsec: JSON.parse(apiToken),
    },
  }).then((response) => response.json());
};

export const getSingleClub = ({ clubId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${clubId}`,
    {
      method: 'GET',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};

export const getMemberList = ({ clubId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${clubId}/members`,
    {
      method: 'GET',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};

export const postActiveClubInfo = ({ clubId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/activate`,
    {
      method: 'POST',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
      body: JSON.stringify({ clubId }),
    }
  ).then((response) => response.json());
};

export const postJoinClub = async ({
  clubId,
  inviteToken,
  code,
  isFreeTrialExpired,
}) => {
  const apiToken = localStorage.getItem('token');

  const response = await fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/join/${clubId}`,
    {
      method: 'POST',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
      body: code
        ? JSON.stringify({ inviteToken, code, isFreeTrialExpired })
        : JSON.stringify({ inviteToken }),
    }
  );

  const data = await response.json();
  return { status: response.status, data };
};

export const deleteMember = ({ clubId, memberId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${clubId}/members/${memberId}`,
    {
      method: 'DELETE',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};
export const verifyClubId = ({ id, clubVerificationId }) => {
  const apiToken = localStorage.getItem('token');
  return fetchData(
    `${process.env._CLUB_BASE_API_URL}api/v01/user/club/${id}/members/${clubVerificationId}/verify`,
    {
      method: 'POST',
      headers: {
        ...headers,
        tsec: JSON.parse(apiToken),
      },
    }
  ).then((response) => response.json());
};
