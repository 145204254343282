import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import FieldElect from 'components/common/FieldElect';
import { FinishLocationIcon, StartLocationIcon } from 'pages/map/icons';
import { VerticalDashedLine } from 'pages/map/components';
import CheckBox from 'components/common/CheckBox';
import {
  CheckBoxSpan,
  RouteName,
  RoutesContentWrap,
  RouteWrap,
  WaypointName,
  WaypointWrap,
  Row,
  InputSearch,
} from './CreateEventPostForm.styles';
import {
  clearEventRoutesList,
  getSavedRoutesStart,
  getSavedRoutesSuccess,
} from 'store/map/actions';
import { AsyncStatus } from 'utils/types';
import { SearchIcon } from 'public/assets/icons';
import {
  ConfirmationModal,
  InputField,
  LoadingComponent,
  NoResultsComponent,
} from 'components';
import { useRouter } from 'next/router';

const DEFAULT_PAGE_SIZE = 10;

const Routes = ({ form }: any) => {
  const { t } = useTranslation();
  const { getSavedRoutesReqStat, savedRoutes } = useSelector(
    (state) => state.map
  );
  const router = useRouter();
  const dispatch = useDispatch();
  const [offset, setOffset] = useState<number>(0);
  const [selectedRoutes, setSelectedRoutes] = useState<any[]>([]); // For multiple selections
  const searchTimer = useRef<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState<string>('');
  const [showLimitModal, setShowLimitModal] = useState<boolean>(false);

  const savedRoutesLengthRef = useRef(savedRoutes?.length || 0);
  savedRoutesLengthRef.current = savedRoutes?.length || 0;

  const observer = useRef<IntersectionObserver | null>(null);

  useEffect(() => {
    const formRoutes = form?.getState()?.values?.routes || [];
    if (formRoutes.length > 0) {
      setSelectedRoutes(formRoutes);
    }
  }, [form]);

  const lastRouteItem = useCallback((node) => {
    if (observer.current) observer?.current?.disconnect();
    observer.current = new IntersectionObserver((entries) => {
      if (entries[0].isIntersecting) {
        setOffset(savedRoutesLengthRef.current);
      }
    });
    if (node) observer.current.observe(node);
  }, []);

  useEffect(() => {
    if (!search) {
      dispatch(getSavedRoutesStart({ offset, limit: DEFAULT_PAGE_SIZE }));
    }
  }, [dispatch, offset, search]);

  // **Handle route selection**
  const handleRouteSelection = (route) => {
    let updatedRoutes;

    if (selectedRoutes.some((r) => r._id === route._id)) {
      updatedRoutes = selectedRoutes.filter((r) => r._id !== route._id);
    } else if (selectedRoutes.length < 10) {
      updatedRoutes = [...selectedRoutes, route];
    } else {
      setShowLimitModal(true);
      return;
    }

    setSelectedRoutes(updatedRoutes);
    form.change('routes', updatedRoutes); // **Sync with form state**
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
    setOffset(0); // Reset pagination on search

    if (searchTimer.current) clearTimeout(searchTimer.current);

    searchTimer.current = setTimeout(() => {
      dispatch(
        getSavedRoutesStart({
          offset: 0,
          limit: DEFAULT_PAGE_SIZE,
          search: e.target.value,
        })
      );
    }, 1000);
  };

  const handleClearSearch = () => {
    dispatch(clearEventRoutesList([]));
    setSearch('');
    setOffset(0);
  };

  return (
    <>
      <FieldElect
        name="routes"
        label={t('event:eventRoutes')}
        headerText={t('event:eventRoutes')}
        multipleSelect={false}
        disabledSubmitBtn={selectedRoutes.length === 0}
        render={() =>
          selectedRoutes && selectedRoutes.length > 0
            ? selectedRoutes.map((item) => (
                <RouteWrap key={item._id}>
                  <RouteName>{item?.name}</RouteName>
                  <WaypointWrap>
                    <StartLocationIcon />
                    <WaypointName>
                      {item?.waypoints[0]?.waypointName}
                    </WaypointName>
                  </WaypointWrap>
                  <VerticalDashedLine margin="0 0 0 11px" />
                  <WaypointWrap>
                    <FinishLocationIcon />
                    <WaypointName>
                      {item?.waypoints[1]?.waypointName}
                    </WaypointName>
                  </WaypointWrap>
                </RouteWrap>
              ))
            : ''
        }
        formRender={() => (
          <>
            <Row>
              <InputSearch>
                <InputField
                  type="text"
                  value={search}
                  onChange={(e) => handleSearchChange(e)}
                  placeholder="Search"
                  crossIcon
                  disabled={getSavedRoutesReqStat === AsyncStatus.LOADING}
                  onCrossClick={handleClearSearch}
                  iconLeft={<SearchIcon />}
                  processing={
                    search && getSavedRoutesReqStat === AsyncStatus.LOADING
                  }
                />
              </InputSearch>
            </Row>
            <RoutesContentWrap>
              {savedRoutes?.length > 0 &&
                savedRoutes?.map((d, i) => (
                  <RouteWrap
                    key={d._id}
                    ref={i === savedRoutes?.length - 1 ? lastRouteItem : null}
                    onClick={() => handleRouteSelection(d)}
                  >
                    <RouteName>{d?.name}</RouteName>

                    <WaypointWrap>
                      <StartLocationIcon />
                      <WaypointName>
                        {d?.waypoints[0]?.waypointName}
                      </WaypointName>
                    </WaypointWrap>

                    <VerticalDashedLine margin="0 0 0 11px" />

                    <WaypointWrap>
                      <FinishLocationIcon />
                      <WaypointName>
                        {d?.waypoints[1]?.waypointName}
                      </WaypointName>
                    </WaypointWrap>
                    <CheckBoxSpan
                      onClick={(e) => e.stopPropagation()} //Prevents parent click from triggering
                    >
                      <CheckBox
                        type="checkbox"
                        checked={selectedRoutes.some((r) => r._id === d._id)}
                        onChange={() => handleRouteSelection(d)}
                      />
                    </CheckBoxSpan>
                  </RouteWrap>
                ))}
              {getSavedRoutesReqStat === AsyncStatus.LOADING && !search && (
                <LoadingComponent
                  fullWidth
                  loaderSize={50}
                  color="#C2C2C2"
                  variant="spin"
                  wrapHeight={100}
                />
              )}
            </RoutesContentWrap>
            {showLimitModal && (
              <ConfirmationModal
                headerText={t('event:limitReached')}
                bodyText={t('event:selectionLimitReached')}
                submitText={t('common:ok')}
                onlySubmitButton={true}
                onSubmit={() => setShowLimitModal(false)}
                onCancelClick={() => setShowLimitModal(false)}
              />
            )}
          </>
        )}
      />
    </>
  );
};

export default Routes;
