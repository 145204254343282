import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';

import Link from 'components/common/Link';
import {
  DiscussionIcon,
  EventsIcon,
  FavoriteIcon,
  GarageIcon,
  GroupsIcon,
  HomeIcon,
  LinkIcon,
  LogOutIcon,
  MapIcon,
  MeetUpIcon,
  MyProfileIcon,
  NotificationsIcon,
  PhotoIcon,
  RoadShareIcon,
  SettingsIcon,
} from 'public/assets/icons';
import { routesPath } from 'utils/constants/routePath';
import { CircleButton } from 'components/Buttons';
import EmptyUserProfilePhoto from 'components/EmptyUserProfilePhoto/EmptyUserProfilePhoto';
import Image from 'components/common/Image';
import {
  openSidebarCreateEvent,
  openSideBarCreateLinkPost,
  openSideBarCreatePhotoPost,
} from 'store/sideBarControler/actions';
import appState from 'hooks/appState';
import { APP_STATE } from 'types';
import { getLocalStorage } from 'utils/hooks';
import AppClubsList from 'containers/Club/AppClubsList';
import { IMobileBottomNav } from './IIMobileBottomNav';

import {
  DropdownWrapper,
  HeaderMobileNavItem,
  MobileBottomNavContainer,
  MobileBottomNavWrapper,
} from './MobileBottomNav.styles';
import { getClubUrl } from 'utils/getClubUrl';

const MobileBottomNav = ({
  userImage,
  onOpenNotification,
  setIsConfirmationModalOpen,
  isSidebarOpen,
  isOpenSidebarSavedRoutes,
  isOpenSidebarWhereTo,
  isOpenSidebarPlanRoute,
  isOpenSidebarChoseStartPoint,
  isOpenSidebarChoseWayPoint,
  isOpenSidebarRouteDetail,
  isOpenSidebarChoseEndPoint,
  isConformationModalOpen,
  viewProfile,
  donationMetricBanner,
}: IMobileBottomNav) => {
  const { feedPath, mapPath, notificationsPath } = routesPath;
  const activeClub = useSelector((state) => state?.club?.activeClub);
  const userDetails = useSelector((store) => store.user?.userDetails);
  const router = useRouter();
  const { route } = router;
  const theme: any = useTheme();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appStateAction = appState();

  const createItems = [
    {
      items: [
        {
          id: 1,
          icon: <PhotoIcon />,
          text: t('event:photoPost'),
          onClick: () => dispatch(openSideBarCreatePhotoPost({})),
        },
        {
          id: 2,
          icon: <LinkIcon />,
          text: t('event:linkPost'),
          onClick: () => dispatch(openSideBarCreateLinkPost({})),
        },
        {
          id: 3,
          icon: <MeetUpIcon />,
          text: t('common:event'),
          onClick: () =>
            appStateAction(APP_STATE.EVENT_CREATE_FORM, {
              isOpen: true,
            }),
        },
        {
          id: 4,
          icon: <RoadShareIcon fill={theme.colors.neutral.n8} />,
          text: t('addPointOfInterest:route'),
          onClick: () =>
            router.push({
              pathname: routesPath.mapPath,
              query: { isNewRoute: true },
            }),
        },
        {
          id: 5,
          icon: <GarageIcon />,
          text: t('map:vehicle'),
          onClick: () =>
            appStateAction(APP_STATE.ADD_VEHICLE_FORM, {
              isOpen: true,
            }),
        },
        {
          id: 6,
          icon: <GroupsIcon />,
          text: t('notifications:group'),
          onClick: () =>
            appStateAction(APP_STATE.CREATE_GROUP_DRAWER, {
              isOpen: true,
            }),
        },
        {
          id: 7,
          icon: <DiscussionIcon height="24" width="24" color="#8D8D8D" />,
          text: t('discussions:discussions'),
          onClick: () =>
            appStateAction(APP_STATE.CREATE_DISCUSSION_FORM, {
              isOpen: true,
            }),
        },
      ],
    },
  ];

  const profileSections = [
    {
      items: [
        {
          id: 1,
          icon: <EventsIcon />,
          text: t('menu:eventsItem'),
          onClick: () => router.push(routesPath.my_events),
        },
        {
          id: 2,
          icon: <GroupsIcon />,
          text: t('menu:groupsItem'),
          onClick: () => router.push(routesPath.my_groups),
        },
        {
          id: 3,
          icon: <RoadShareIcon fill={theme.colors.neutral.n8} />,
          text: t('menu:routeItem'),
          onClick: () => router.push(routesPath.my_routes),
        },
        {
          id: 4,
          icon: <GarageIcon />,
          text: t('menu:garageItem'),
          onClick: () => router.push(routesPath.my_garage),
        },
        {
          id: 5,
          icon: <DiscussionIcon color="#8D8D8D" width="22" height="22" />,
          text: t('menu:discussionItem'),
          onClick: () => router.push(routesPath.my_discussions),
        },
        {
          id: 6,
          icon: <FavoriteIcon />,
          text: t('menu:favoritesItem'),
          onClick: () => router.push(routesPath.my_favorites),
        },
        {
          id: 7,
          icon: <SettingsIcon />,
          text: t('profile:settings'),
          onClick: () => router.push(`${routesPath.settings}`),
        },
        {
          id: 8,
          icon: <LogOutIcon />,
          text: t('settings:logOut'),
          onClick: () => setIsConfirmationModalOpen(true),
        },
        {
          id: 9,
          icon: <GroupsIcon />,
          text: t('common:openMyEvents'),
          onClick: () => {
            const url = `/${getClubUrl(activeClub)}`;
            router.push(url);
          },
          hidden: !userDetails?.clubRoles?.some(
            (club) => club.clubId === activeClub?._id && club.role === 'admin'
          ),
        },
      ],
    },
    {
      header: t('menu:switchClubs'),
      items: [
        {
          customContent: <AppClubsList />,
        },
      ],
    },
  ];

  return (
    <MobileBottomNavWrapper
      isSidebarOpen={
        isSidebarOpen ||
        isOpenSidebarSavedRoutes ||
        isOpenSidebarWhereTo ||
        isOpenSidebarPlanRoute ||
        isOpenSidebarChoseStartPoint ||
        isOpenSidebarChoseWayPoint ||
        isOpenSidebarRouteDetail ||
        isOpenSidebarChoseEndPoint
      }
      isConformationModalOpen={isConformationModalOpen}
    >
      <MobileBottomNavContainer>
        <HeaderMobileNavItem>
          <Link path={feedPath}>
            <HomeIcon
              colorStroke={
                route === feedPath ? theme.colors.orange : theme.colors.black
              }
            />
          </Link>
        </HeaderMobileNavItem>
        <HeaderMobileNavItem>
          <Link path={mapPath}>
            <MapIcon
              colorStroke={
                route === mapPath ? theme.colors.orange : theme.colors.black
              }
            />
          </Link>
        </HeaderMobileNavItem>
        <HeaderMobileNavItem>
          <DropdownWrapper sections={createItems}>
            <CircleButton size={50} />
          </DropdownWrapper>
        </HeaderMobileNavItem>
        <HeaderMobileNavItem onClick={onOpenNotification}>
          <NotificationsIcon
            colorStroke={
              route === notificationsPath
                ? theme.colors.orange
                : theme.colors.black
            }
          />
        </HeaderMobileNavItem>
        <HeaderMobileNavItem>
          <DropdownWrapper
            viewProfile={viewProfile}
            sections={profileSections}
            donationMetricBanner={donationMetricBanner}
          >
            {userImage ? (
              <Image src={userImage} alt="user image" width={32} height={32} />
            ) : (
              <EmptyUserProfilePhoto
                wrapSize={32}
                userIconSize={16}
                withBorder={false}
              />
            )}
          </DropdownWrapper>
        </HeaderMobileNavItem>
      </MobileBottomNavContainer>
    </MobileBottomNavWrapper>
  );
};

export default MobileBottomNav;
