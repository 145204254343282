import validate from 'validate.js';
import _ from 'lodash';

import i18n from './i18n';
import fetchData from './fetchData';

export const PASSWORD_MIN_LENGTH = 8;
export const PASSWORD_MAX_LENGTH = 256;

// Final Form expects and undefined value for a successful validation
const VALID = undefined;

// Source: http://www.regular-expressions.info/email.html
// See the link above for an explanation of the tradeoffs.
const EMAIL_RE = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
// const USERNAME_RE = /^[a-z0-9_]+.+$/;
const USERNAME_CHECK = /^[a-z0-9_.]*$/;
const LOWER_UPPERCASE = /(?=.*[a-z])(?=.*[A-Z])/g;
const PHONE_RE =
  /^(\+?[1-9]\d{1,3})?[-.\s]?(\(?\d{1,5}\)?[-.\s]?)?\d{1,4}[-.\s]?\d{1,4}[-.\s]?\d{1,9}$/;

const presence = () => ({ message: i18n.t('event:thisFieldIsRequired') });

export const validateEmail = (email, isRequired = false) => {
  const constraints = {
    email: {
      presence: isRequired ? presence() : null,
      email: {
        message: i18n.t('auth:emailNotValid'),
      },
    },
  };
  return validate({ email }, constraints);
};

export const validateUrl = (url, isRequired = false) => {
  const schemas = ['http', 'https'];
  const urlProcessed = schemas.some((schema) =>
    _.startsWith(url, `${schema}://`)
  )
    ? url
    : `http://${url}`;

  const constraints = {
    url: {
      presence: isRequired ? presence() : null,
      url: {
        message: i18n.t('link:incorrectLinkFormat'),
        schemas,
      },
    },
  };
  return validate({ url: urlProcessed }, constraints);
};

const isNonEmptyString = (val) => {
  return typeof val === 'string' && val.trim().length > 0;
};

const isNonEmptyObject = (val) => {
  return typeof val === 'object' && Object.keys(val)?.length > 0;
};

export const passwordRequirementsValidation = (message) => (value) => {
  return typeof value === 'string' &&
    value.length &&
    (value.length < 8 || !LOWER_UPPERCASE.test(value))
    ? message
    : VALID;
};

export const requiredStringNoTrim = (message) => (value) => {
  return typeof value === 'string' && value.length > 0 ? VALID : message;
};

export const requiredImage = (message) => (value) => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return message;
  }
  if (typeof value === 'object' || typeof value === 'string') {
    // undefined or null values are invalid
    return value?.length > 0 ? VALID : message;
  }
  return VALID;
};

export const validateGroupUsername = (oldUsername) => async (value) => {
  try {
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const token =
      typeof window !== 'undefined' ? localStorage.getItem('token') : '';
    if (oldUsername !== value) {
      const response = await fetchData(
        `${process.env._DB_HOST}api/v01/group/username/${value}`,
        {
          method: 'GET',
          headers: {
            ...headers,
            tsec: JSON.parse(token),
          },
        }
      );
      const res = await response.json();
      if (res?.code === 400) {
        return res?.data?.msg;
      }
      return VALID;
    }
  } catch (error) {
    console.log(error);
  }
};

export const required = (message) => (value) => {
  if (typeof value === 'undefined' || value === null) {
    // undefined or null values are invalid
    return message;
  }
  if (typeof value === 'string') {
    // string must be nonempty when trimmed
    return isNonEmptyString(value) ? VALID : message;
  }
  if (typeof value === 'object') {
    // string must be nonempty when trimmed
    return isNonEmptyObject(value) ? VALID : message;
  }
  return VALID;
};

export const requiredCheckBox = (message) => (value) => {
  if (typeof value === 'undefined' || value === false) {
    return message;
  }

  return VALID;
};

export const minLength = (message, minimumLength) => (value) => {
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length >= minimumLength ? VALID : message;
};

export const maxLength = (message, maximumLength) => (value) => {
  if (!value) {
    return VALID;
  }
  const hasLength = value && typeof value.length === 'number';
  return hasLength && value.length <= maximumLength ? VALID : message;
};

export const emailFormatValid = (message) => (value) => {
  return value && EMAIL_RE.test(value) ? VALID : message;
};

export const userNameValid = (message) => (value) => {
  return value && USERNAME_CHECK.test(value) ? VALID : message;
};

export const composeValidators =
  (...validators) =>
  (value) =>
    validators
      .filter(Boolean) // ✅ Remove undefined validators
      .reduce((error, validator) => error || validator(value), VALID);

export const validatePhoto = (
  value: any,
  errorMessage: string = 'This field is required'
) => {
  return value ? undefined : errorMessage;
};
export const phoneNumberValid = (message: string) => (value: string) => {
  return value && PHONE_RE.test(value) ? VALID : message;
};
