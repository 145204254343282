import { routesPath } from './constants/routePath';

export const getClubUrl = (activeClub?: {
  _id?: string;
  name?: string;
}): string => {
  if (!activeClub?._id || !activeClub?.name) return '';

  const activeClubName = activeClub.name.replace(/\s+/g, '-').toLowerCase();
  return `${routesPath.club}/${activeClub._id}/${activeClubName}`;
};
