import React, { useEffect, useState } from 'react';

import i18n from 'utils/i18n';
import { CrossIcon, TickIcon } from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { IConfirmationModal } from './IConfirmationModal';
import {
  ContentWrap,
  ModalWrap,
  HeaderText,
  BodyText,
  CloseIconWrap,
  ReportItemWrap,
  CustomRadioWrap,
  ReportMessageText,
} from './ConfirmationModal.style';
import { RectangleButton } from '../../index';

export const ConfirmationModal = ({
  headerText,
  bodyText,
  submitText,
  cancelText = i18n.t('common:cancelUppercase'),
  onSubmit,
  onCancelClick,
  loading,
  isReport,
  onReportSubmit,
  onlySubmitButton,
  onAdditionalCancelClick,
}: IConfirmationModal) => {
  const [selectedReportVariant, setSelectedReportVariant] = useState(0); // 0 - Inappropriate content, 1 - spam

  const onModalWrapClick = (e) => {
    e.stopPropagation();
  };

  const onReportItemClick = (value) => {
    setSelectedReportVariant(value);
  };

  const onReportSubmitClick = () => {
    const isSpam = selectedReportVariant === 1;
    const reportData = {
      type: isSpam ? 4 : 3,
    };
    onReportSubmit(reportData);
  };

  useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'unset';
      document.body.style.overflowX = 'hidden';
    };
  }, []);

  return (
    <ContentWrap onClick={onCancelClick}>
      <ModalWrap onClick={onModalWrapClick}>
        <HeaderText>{headerText}</HeaderText>
        <BodyText>{bodyText}</BodyText>

        {isReport && (
          <>
            <ReportItemWrap
              isSelected={selectedReportVariant === 0}
              mb={10}
              onClick={() => onReportItemClick(0)}
            >
              <ReportMessageText>
                {i18n.t('settings:inappropriateContent')}
              </ReportMessageText>
              <CustomRadioWrap isSelected={selectedReportVariant === 0}>
                {selectedReportVariant === 0 && <TickIcon />}
              </CustomRadioWrap>
            </ReportItemWrap>
            <ReportItemWrap
              isSelected={selectedReportVariant === 1}
              mb={16}
              onClick={() => onReportItemClick(1)}
            >
              <ReportMessageText>
                {i18n.t('common:reportSpam')}
              </ReportMessageText>
              <CustomRadioWrap isSelected={selectedReportVariant === 1}>
                {selectedReportVariant === 1 && <TickIcon />}
              </CustomRadioWrap>
            </ReportItemWrap>
          </>
        )}

        <RectangleButton
          text={submitText}
          color={style.mainWhite.white}
          backgroundColor={style.mainYellow.yellow}
          onClick={isReport ? onReportSubmitClick : onSubmit}
          loading={loading}
        />

        {!onlySubmitButton && (
          <RectangleButton
            text={cancelText}
            color={style.mainBlack.black}
            backgroundColor={style.mainGray.whiteSmokeColor}
            onClick={
              onAdditionalCancelClick ? onAdditionalCancelClick : onCancelClick
            }
            margin="16px 0 0 0"
          />
        )}

        <CloseIconWrap onClick={onCancelClick}>
          <CrossIcon color="#8D8D8D" />
        </CloseIconWrap>
      </ModalWrap>
    </ContentWrap>
  );
};
