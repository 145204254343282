import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setClubIdInStorage } from 'utils/storageUtils';
import { clearAppClubState as chatClearAppClubState } from 'store/chat/actions';
import { clearAppClubState as mapClearAppClubState } from 'store/map/actions';
import { clearAppClubState as searchClearAppClubState } from 'store/search/actions';
import { postActiveClubInfoRequest, resetClubsData } from 'store/club/actions';
import { clearAppState } from 'store/fetchApi/actions';
import { useRouter } from 'next/router';
import { retainSamePathOnReload } from './clubUtils';
import { MomentFromTimeStampToNow } from './MomentFromTimeStampToNow';

const useSwitchAppClubAccount = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const newActiveClubId = useRef(null);
  const activeAccountId =
    useSelector((state) => state?.club?.activeClub)?._id || null;
  const activeClub = useSelector((state) => state?.club?.activeClub) || null;
  const [refreshData, setRefreshData] = useState(false);
  const pathName = router?.pathname;
  const pathNameRef = useRef(pathName);
  pathNameRef.current = pathName;
  useEffect(() => {
    if (refreshData && activeAccountId === newActiveClubId.current) {
      setRefreshData(false);
      setClubIdInStorage(activeAccountId);
      if (retainSamePathOnReload(pathNameRef.current)) {
        return router.reload();
      }
      if (
        router?.isReady &&
        router?.pathname === '/joinClub' &&
        activeClub?.config?.clubJoinRequired
      ) {
        window.history.pushState(null, '', router.asPath);
      } else router?.push('/feed').then(() => router.reload());
    }
  }, [refreshData, activeAccountId]);

  const resetStates = useCallback(
    (clubId) => {
      dispatch(postActiveClubInfoRequest(clubId));
      dispatch(chatClearAppClubState());
      dispatch(mapClearAppClubState());
      dispatch(searchClearAppClubState());
      dispatch(clearAppState());
      setRefreshData(true);
    },
    [dispatch]
  );

  const _handleClubSwitch = useCallback(
    (newClubId) => {
      if (newClubId === 'RoadStr') {
        newActiveClubId.current = null;
      } else {
        newActiveClubId.current = newClubId;
      }
      resetStates(newClubId);
    },
    [resetStates, dispatch]
  );

  const handleClubSwitch = useCallback(
    (newClubId) => {
      _handleClubSwitch(newClubId);
    },
    [_handleClubSwitch]
  );

  return {
    handleClubSwitch,
    activeAccountId,
  };
};

export default useSwitchAppClubAccount;
