import { InputField } from 'components';
import React, { useState } from 'react';
import { Select } from 'antd';
import CheckBox from 'components/common/CheckBox';
import { Field } from 'react-final-form';
import {
  LabelCheckboxWrap,
  LabelText,
} from 'components/Modals/FieldDetailsModal/FieldDetailsModal.style';
import { useTranslation } from 'react-i18next';
import { DatePicker } from 'antd';

export enum FormType {
  Registration = 'registration',
  Payment = 'payment',
  Waiver = 'waiver',
}

export type AllFormTypes = FormType.Registration | FormType.Payment | FormType.Waiver;

export enum FormFieldType {
  TextBox = 'text',
  MultipleOptions = 'multiple',
  Paragraph = 'paragraph',
  Checkbox = 'checkbox',
  DatePicker = 'date',
  // File = 'file',
  SIGNATURE = 'signature',
  Phone = 'phone',
}

export type AllFormFieldTypes =
  | FormFieldType.TextBox
  | FormFieldType.MultipleOptions
  | FormFieldType.Paragraph
  | FormFieldType.Checkbox
  | FormFieldType.DatePicker
  // | FormFieldType.File
  | FormFieldType.SIGNATURE
  | FormFieldType.Phone;

export const FormFieldTypeLabels: Record<FormFieldType, string> = {
  [FormFieldType.TextBox]: 'textBox',
  [FormFieldType.MultipleOptions]: 'multipleOptions',
  [FormFieldType.Paragraph]: 'paragraph',
  [FormFieldType.Checkbox]: 'checkbox',
  [FormFieldType.DatePicker]: 'datePicker',
  // [FormFieldType.File]: 'file',
  [FormFieldType.SIGNATURE]: 'signature',
  [FormFieldType.Phone]: 'phone',
};

interface IBaseField {
  _id?: string;
  name: string;
  type: string;
  label: string;
  required: boolean;
  value?: string;
  onChange?: (value: string) => void;
}

// export interface IFileField extends IBaseField {
//   type: FormFieldType.File;
//   content?: string;
// }

export interface ISignatureField extends IBaseField {
  type: FormFieldType.SIGNATURE;
  content?: string;
}
export interface ITextField extends IBaseField {
  type: FormFieldType.TextBox;
  maxLength?: number;
}

export interface IPhoneField extends IBaseField {
  type: FormFieldType.Phone;
  maxLength?: number;
}

export interface IMultipleField extends IBaseField {
  type: FormFieldType.MultipleOptions;
  isMultiSelect?: boolean;
  options: {
    _id?: string;
    label: string;
    value: string;
    monetaryValue?: number;
    maxQuantity?: number;
  }[];
}

export interface IParagraphField extends IBaseField {
  type: FormFieldType.Paragraph;
  maxLength?: number;
}

export interface ICheckboxField extends IBaseField {
  type: FormFieldType.Checkbox;
  monetaryValue?: number;
  maxQuantity?: number;
  alwaysChecked?: boolean;
}

export interface IDateField extends IBaseField {
  type: FormFieldType.DatePicker;
  isRangedDate?: boolean;
}

export type IFormField =
  | ITextField
  | IMultipleField
  | IParagraphField
  | ICheckboxField
  | IDateField
  // | IFileField
  | ISignatureField
  | IPhoneField;

export interface FormFieldProps {
  field: IFormField;
  disabled?: boolean;
  currency?: string;
}

export const FormField = ({ field, disabled, currency }: FormFieldProps) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(field.value || '');

  const handleChange = (newValue: string) => {
    setValue(disabled ? '' : newValue);
    if (field.onChange) {
      field.onChange(disabled ? '' : newValue);
    }
  };

  const getTranslatedLabel = (
    label: string,
    monetaryValue?: string | number
  ) => {
    const translatedLabel =
      t(`event:${label}`) === `event:${label}` ? label : t(`event:${label}`);

    if (monetaryValue) {
      const formattedValue = currency
        ? new Intl.NumberFormat(undefined, {
            style: 'currency',
            currency,
          }).format(+monetaryValue / 100)
        : `$${+monetaryValue / 100}`;
      return `${translatedLabel} - ${formattedValue}`;
    }

    return translatedLabel;
  };

  return (
    <div style={{ marginTop: 10 }}>
      {field.type === FormFieldType.TextBox && (
        <InputField
          value={value}
          type="text"
          disabled={disabled}
          maxLength={field.maxLength || 50}
          showCharactersNumber
          placeholder={getTranslatedLabel(field.label)}
        />
      )}
      {field.type === FormFieldType.Phone && (
        <InputField
          value={value}
          type="number"
          disabled={disabled}
          maxLength={field.maxLength || 50}
          showCharactersNumber
          placeholder={getTranslatedLabel(field.label)}
        />
      )}

      {field.type === FormFieldType.MultipleOptions && (
        <Select
          mode="multiple"
          placeholder={field.label}
          onChange={handleChange}
          value={disabled ? null : undefined}
          options={field.options?.map((option) => ({
            value: option.value,
            label: getTranslatedLabel(option.label),
          }))}
          disabled={disabled}
          variant="borderless"
          style={{
            width: '100%',
            height: 44,
            outline: 'none',
            fontFamily: 'Poppins',
            fontWeight: 400,
            background: '#f7f7f7',
          }}
        />
      )}

      {field.type === FormFieldType.Paragraph && (
        <InputField
          value={value}
          disabled={disabled}
          type="textarea"
          maxLength={field.maxLength || 500}
          showCharactersNumber
          placeholder={getTranslatedLabel(field.label)}
        />
      )}

      {field.type === FormFieldType.Checkbox && (
        <>
          <Field name="required" type="checkbox">
            {(props) => (
              <LabelCheckboxWrap>
                <CheckBox
                  {...props.input}
                  type="checkbox"
                  checked={props.input.checked}
                  height={8}
                  width={8}
                  disabled={disabled}
                />
                <LabelText>
                  {getTranslatedLabel(field.label, field.monetaryValue)}
                </LabelText>
              </LabelCheckboxWrap>
            )}
          </Field>
        </>
      )}

      {field.type === FormFieldType.DatePicker &&
        (field.isRangedDate ? (
          <>
            <DatePicker.RangePicker
              disabled={disabled}
              placeholder={[field.name, '']}
              style={{
                width: '100%',
                height: 52,
                background: '#f7f7f7',
              }}
            />
          </>
        ) : (
          <InputField
            label={getTranslatedLabel(field.label)}
            value={value}
            onChange={(e) => handleChange(e.target.value)}
            type="date"
            showCharactersNumber
            disabled={disabled}
            placeholder={getTranslatedLabel(field.label)}
          />
        ))}
    </div>
  );
};
