/* eslint-disable no-unused-expressions */
import React, { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SidebarWrapper, InputField, LocationPlacesList } from 'components';
import { closeSidebarChoseEndPoint } from 'store/map/actions';
import { fetchPlaceDetails, getPlaceName, searchPlace } from 'services';
import {
  ArrowBack,
  CurrentLocationIcon,
  LocationArrowIcon,
} from 'public/assets/icons';
import { ISidebarChoseEndPoint } from './ISidebarChoseEndPoint';
import {
  CurrentLocationContainer,
  HeaderText,
} from './SidebarChoseEndPoint.style';
import { MapSidebarWrap } from '../index';
import { v4 as uuidv4 } from 'uuid';

const SidebarChoseEndPoint = ({
  userCoordinates,
  viewport,
  setViewport,
}: ISidebarChoseEndPoint) => {
  const sessionToken = useRef(uuidv4());
  const [locationInputValue, setLocationInputValue] = useState('');
  const [placesOffered, setPlacesOffered] = useState([]);
  const [isOfferedPlacesModalOpen, setIsOfferedPlacesModalOpen] =
    useState(false);
  const [submitLocationData, setSubmitLocationData] = useState(null);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const onChangePlace = (newText) => {
    setLocationInputValue(newText);

    const proximity = userCoordinates
      ? `${userCoordinates[0]},${userCoordinates[1]}`
      : '';

    const searchPlaceByText = async () => {
      const resp = await searchPlace({
        searchText: newText.trim(),
        proximity,
        limit: 10,
        sessionToken: sessionToken.current,
      });

      if (!resp) {
        return null;
      }

      const { predictions } = resp?.data;
      if (predictions?.length) {
        setPlacesOffered(predictions);
        !isOfferedPlacesModalOpen && setIsOfferedPlacesModalOpen(true);
      } else {
        setPlacesOffered([]);
        setIsOfferedPlacesModalOpen(false);
      }
    };

    if (newText.length) {
      searchPlaceByText();
    } else {
      setIsOfferedPlacesModalOpen(false);
      setSubmitLocationData(null);
    }
  };

  const onPlaceOfferedClick = async (place) => {
    const response = await fetchPlaceDetails({
      placeId: place?.place_id,
      sessionToken: sessionToken.current,
    });
    if (!response) {
      return null;
    }
    const { data } = response;
    if (data) selectedPlace(data);
  };

  const selectedPlace = (place) => {
    setIsOfferedPlacesModalOpen(false);
    setLocationInputValue(place?.name);

    const { lat, lng } = place?.geometry?.location;

    setSubmitLocationData({
      lat: lat,
      lng: lng,
      name: place?.name,
      address: place?.formatted_address,
    });
    setViewport({ ...viewport, latitude: lat, longitude: lng, zoom: 13 });
  };

  return (
    <MapSidebarWrap>
      <SidebarWrapper
        headerText={t('map:chooseEndPoint')}
        headerIcon={<ArrowBack />}
        submitText={t('common:add')}
        isSubmitAvailable
        onSubmit={() => null}
        onIconClick={() => dispatch(closeSidebarChoseEndPoint())}
        contentPadding="20px"
      >
        <InputField
          value={locationInputValue}
          onChange={(e) => onChangePlace(e.target.value)}
          iconLeft={<LocationArrowIcon />}
          placeholder={t('map:typeAPlace')}
          label={t('map:searchLocation')}
          crossIcon
          onCrossClick={() => {
            setLocationInputValue('');
            setSubmitLocationData(null);
            setPlacesOffered([]);
            setIsOfferedPlacesModalOpen(false);
          }}
        />

        <CurrentLocationContainer>
          <CurrentLocationIcon />

          <HeaderText ml={12}>{t('map:useCurrentLocation')}</HeaderText>
        </CurrentLocationContainer>

        {isOfferedPlacesModalOpen && (
          <LocationPlacesList
            locationsList={placesOffered}
            onOutsideClick={() => setIsOfferedPlacesModalOpen(false)}
            onItemClick={(item) => onPlaceOfferedClick(item)}
            top={86}
            left={20}
          />
        )}
      </SidebarWrapper>
    </MapSidebarWrap>
  );
};

export default SidebarChoseEndPoint;
