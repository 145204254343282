import { error } from 'console';
import fetchData from 'utils/fetchData';

const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

export const createPost = (postDetails) => {
  const token = localStorage.getItem('token');
  let requestUrl = `${process.env._DB_HOST}api/`;

  if (postDetails.publishAt) {
    requestUrl += 'v10/media/schedule';
  } else {
    requestUrl += 'v01/media';
  }

  return fetchData(requestUrl, {
    method: 'POST',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
    body: JSON.stringify(postDetails),
  }).then((response) => response.json());
};

export const createEvent = (eventData) => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v01/event`, {
    method: 'post',
    headers: {
      ...headers,
      tsec: JSON.parse(token),
    },
    body: JSON.stringify(eventData),
  }).then((response) => (response.status === 204 ? null : response.json()));
};

export const getEventDetails = (id) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOSTv12}api/v12/event/${id}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEventParticipants = (id) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/event/${id}/participants`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEventVehicles = (eventId, offset = 0, limit = 20) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/event/${eventId}/vehicles?offset=${offset}&limit=${limit}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEventPosts = ({ id, limit = 20, start = 0, pin }) => {
  const token = localStorage.getItem('token');
  try {
    return fetchData(
      `${
        process.env._DB_HOST
      }api/v10/event/${id}/posts?limit=${limit}&start=${start}${
        pin ? `&pin=${pin}` : ''
      }`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getGroupPosts = ({ id, limit = 20, start = 0, pin }) => {
  try {
    const token = localStorage.getItem('token');

    // Dynamically construct the URL based on the presence of 'pin'
    let url = `${process.env._DB_HOST}api/v10/group/${id}/posts?limit=${limit}&start=${start}`;
    if (pin) {
      url += `&pin=${pin}`;
    }

    return fetchData(url, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

// export const getGroupPosts = ({ id, limit = 20, start = 0, pin }) => {
//   try {
//     const token = localStorage.getItem('token');

//     return fetchData(
//       `${process.env._DB_HOST}api/v10/group/${id}/posts?limit=${limit}&start=${start}&pin=${pin}`,
//       {
//         method: 'GET',
//         headers: { ...headers, tsec: JSON.parse(token) },
//       }
//     ).then((response) => response.json());
//   } catch (error) {
//     console.log(error);
//   }
// };

export const getGroupVehicles = (groupId) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/group/${groupId}/vehicles`,
      {
        method: 'GET',
        headers: { ...headers, tsec: token },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const addVehiclesToGroup = (groupId, vehicles = []) => {
  try {
    const token = JSON.parse(localStorage.getItem('token'));

    return fetchData(
      `${process.env._DB_HOST}api/v01/group/${groupId}/garage/vehicle`,
      {
        method: 'POST',
        headers: { ...headers, tsec: token },
        body: JSON.stringify({ vehicles }),
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const parseLink = (link) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/parser?url=${link}`, {
      method: 'get',
      headers: {
        ...headers,
        tsec: JSON.parse(token),
      },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getPopularPosts = () => {
  const token = localStorage.getItem('token');

  return fetchData(`${process.env._DB_HOST}api/v05/search/posts/popular`, {
    method: 'GET',
    headers: { ...headers, tsec: JSON.parse(token) },
  }).then((response) => response.json());
};

export const getPostDetails = (postId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/media/${postId}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((resp) => resp.json());
  } catch (error) {
    console.log(error);
  }
};

export const reportPost = ({ postId, reportData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/media/${postId}/report`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(reportData),
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const reportGroup = ({ groupId, reportData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/group/${groupId}/report`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(reportData),
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const reportEvent = ({ eventId, reportData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}/report`, {
      method: 'POST',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(reportData),
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const editGroup = ({ groupId, groupData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/group/${groupId}`, {
      method: 'PATCH',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(groupData),
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const getGroupDetails = (groupId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v10/group/${groupId}`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getGroupAdmins = (groupId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/group/${groupId}/admins`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const editEvent = ({ eventId, eventData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}`, {
      method: 'PATCH',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(eventData),
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const cancelEvent = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}/cancel`, {
      method: 'PUT',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const deleteEvent = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}`, {
      method: 'DELETE',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const editPost = ({ postId, postData }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/media/${postId}`, {
      method: 'PATCH',
      headers: { ...headers, tsec: JSON.parse(token) },
      body: JSON.stringify(postData),
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const deletePost = (postId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/media/${postId}`, {
      method: 'DELETE',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const removeGroupTag = ({ postId, groupId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/media/${postId}/group/${groupId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const removeMeFromTags = ({ postId, userId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/media/${postId}/user/${userId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const removeMeFromCollaborators = ({ postId, collaboratorId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/media/${postId}/collaborators/${collaboratorId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const attendEventWithVehicle = ({
  eventId,
  vehicleId,
}): Promise<any> => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/event/${eventId}/attend/${vehicleId}`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const attendEventWithoutVehicle = (eventId): Promise<any> => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}/attend`, {
      method: 'PUT',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const cancelEventSubscription = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/event/${eventId}/leave-attendee/`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((resp) => (resp.status === 204 ? resp : resp.json()));
  } catch (error) {
    console.log(error);
  }
};

export const getGroupFollowers = ({ groupId, offset, limit, search = '' }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/group/${groupId}/followers?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getGroupMembers = ({ groupId, offset, limit, search = '' }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/group/${groupId}/members?offset=${offset}&limit=${limit}&search=${search}`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const deleteGroupFollower = ({ groupId, followerId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/group/${groupId}/followers/${followerId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

export const deleteGroupMember = ({ groupId, memberId }) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/group/${groupId}/member/${memberId}`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) =>
      response.status === 204 ? response : response.json()
    );
  } catch (error) {
    console.log(error);
  }
};

// eslint-disable-next-line camelcase
export const getGroupAdmins_v12 = (groupId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOSTv12}api/v12/group/${groupId}/admins`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEventInvited = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/event/${eventId}/invited`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEventAttending = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/event/${eventId}/attending`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getEventWaitListing = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/event/${eventId}/waitlisted`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getLikesPost = (postId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/media/${postId}/like`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getLikesVehicle = (vehicleId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/cars/detail/${vehicleId}/like`,
      {
        method: 'GET',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};

export const getLikesEvent = (eventId) => {
  try {
    const token = localStorage.getItem('token');

    return fetchData(`${process.env._DB_HOST}api/v01/event/${eventId}/like`, {
      method: 'GET',
      headers: { ...headers, tsec: JSON.parse(token) },
    }).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
export const putFavouriteMedia = (postId) => {
  console.log('putFavouriteMediaPOStID', postId);
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/media/${postId}/favorite`,
      {
        method: 'PUT',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
export const deleteFavouriteMedia = (postId) => {
  console.log('DeleteFavouriteMediaPOStID', postId);
  try {
    const token = localStorage.getItem('token');

    return fetchData(
      `${process.env._DB_HOST}api/v01/media/${postId}/favorite`,
      {
        method: 'DELETE',
        headers: { ...headers, tsec: JSON.parse(token) },
      }
    ).then((response) => response.json());
  } catch (error) {
    console.log(error);
  }
};
