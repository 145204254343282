import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';

import { RectangleButton, EmptyUserProfilePhoto } from 'components';
import { EventChatIcon, LockIcon } from 'public/assets/icons';
import { style } from 'utils/constants/style';
import { followUser, unfollowUser } from 'services/followUser';
import { routesPath } from 'utils/constants/routePath';
import {
  SuggestedItem,
  SuggestedItemImage,
  SuggestedGroupIcon,
  SuggestedItemInfoWrap,
  SuggestedItemHeaderText,
  SuggestedItemText,
  ButtonWrap,
} from './Suggestions.style';
import { useDispatch } from 'react-redux';
import { postJoinGroupError, postJoinGroupStart, postUnjoinGroupStart } from 'store/user/actions';
interface ISuggestionItem {
  paginationRef: (node: any) => void;
  onItemClick: () => void;
  data: {
    _id: string;
    isGroup: boolean;
    profilePhoto: string;
    username: string;
    name: string;
    followingStatus: number;
    memberStatus: number;
    privacy: number;
  };
}

export const SuggestionItem = ({
  paginationRef,
  data,
  onItemClick,
}: ISuggestionItem) => {
  const {
    isGroup,
    privacy,
    profilePhoto,
    username,
    name,
    memberStatus,
    followingStatus,
    _id,
  } = data;

  const [status, setStatus] = useState<number>(
    isGroup ? memberStatus : followingStatus
  );
  const dispatch = useDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const isPrivate = !!privacy;

  const router = useRouter();
  const { t } = useTranslation();

  const joinGroup = (id, code: string = null) => {
    console.log('JOIN GROUP CALLED');
    dispatch(
      postJoinGroupStart({
        groupId: id,
        code: null,
        data,
        onSuccess: (res) => {
          if (res.code === 400) {
            dispatch(postJoinGroupError(res?.data?.msg));
          }
          if (res.ok) {
            setStatus(isPrivate ? 1 : 2);
          }
          setLoading(false);
        },
      })
    );
  };

  const unJoinGroup = async (id: string) => {
    dispatch(
      postUnjoinGroupStart({
        groupId: id,
        onSuccess: (resp) => {
          if (resp.ok) {
            setStatus(0);
          }
          setLoading(false);
        },
      })
    );
  };

  const onButtonClick = (id) => {
    if (status !== 1) {
      setLoading(true);
    }

    if (isGroup) {
      if (status === 0) {
        joinGroup(id);
      }

      if (status === 2) {
        unJoinGroup(id);
      }
    } else {
      if (status === 0) {
        followUser(id).then((resp) => {
          if (resp.ok) {
            setStatus(isPrivate ? 1 : 2);
          }

          setLoading(false);
        });
      }

      if (status === 2) {
        unfollowUser(id).then((resp) => {
          if (resp.ok) {
            setStatus(0);
          }

          setLoading(false);
        });
      }
    }
  };

  const onCardClick = (id) => {
    if (isGroup) {
      router.push(`${routesPath.group_details}/${id}`);
    } else {
      router.push(`${routesPath.user_details}/${id}`);
    }

    onItemClick();
  };

  const getButtonText = () => {
    switch (status) {
      case 0:
        return isGroup ? t('group:joinUppercase') : t('group:followUppercase');

      case 1:
        return t('group:requestedUppercase');

      case 2:
        return isGroup
          ? t('group:memberUppercase')
          : t('group:followingUppercase');

      default:
        return '';
    }
  };

  return (
    <SuggestedItem ref={paginationRef} onClick={() => onCardClick(_id)}>
      {profilePhoto?.trim() ? (
        <SuggestedItemImage img={profilePhoto}>
          {isGroup && (
            <SuggestedGroupIcon>
              <EventChatIcon />
            </SuggestedGroupIcon>
          )}
        </SuggestedItemImage>
      ) : (
        <EmptyUserProfilePhoto
          wrapSize={48}
          userIconSize={24}
          withBorder={false}
          margin="0 10px 0 0"
          cursor="pointer"
          icon={
            isGroup ? (
              <SuggestedGroupIcon>
                <EventChatIcon />
              </SuggestedGroupIcon>
            ) : null
          }
        />
      )}

      <SuggestedItemInfoWrap>
        <SuggestedItemHeaderText>{username}</SuggestedItemHeaderText>
        {isGroup && <SuggestedItemText>{name}</SuggestedItemText>}
      </SuggestedItemInfoWrap>

      <ButtonWrap onClick={(e) => e.stopPropagation()}>
        <RectangleButton
          text={getButtonText()}
          color={status ? style.mainBlue.blue : style.mainWhite.white}
          backgroundColor={status ? style.mainWhite.white : style.mainBlue.blue}
          onClick={() => onButtonClick(_id)}
          height="32px"
          icon={isPrivate && status !== 1 ? <LockIcon /> : null}
          buttonTextMargin={isPrivate && status !== 1 ? '0 0 0 4px' : ''}
          border={status ? `1px solid ${style.mainBlue.blue}` : ''}
          loading={loading}
          mobileHeight={32}
        />
      </ButtonWrap>
    </SuggestedItem>
  );
};
