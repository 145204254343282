/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import SelectVehicle from './SelectVehicle';
import { ICreateEventPostForm } from './ICreateEventPostForm';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import {
  FinalFormWrapper,
  FormWrapper,
  SelectVehicleWrapper,
  StepNumber,
  StepNumberWrap,
} from './CreateEventPostForm.styles';

const CreateEventPostForm = (props: ICreateEventPostForm) => {
  const { t } = useTranslation();
  const drawerContentRef = props.drawerContentRef;

  const [stepNumber, setStepNumber] = useState(1);
  const [data, setData] = useState({
    brands: null,
    collaborators: null,
    location: null,
  });

  useEffect(() => {
    if (drawerContentRef?.current) {
      drawerContentRef.current.scrollToTop();
    }
  }, [stepNumber, drawerContentRef]);

  return (
    <>
      <FinalFormWrapper
        {...props}
        mutators={{
          setFieldTouched: ([field], state, utils) => {
            state.fields[field].touched = true;
          },
        }}
        render={(formRenderProps: FormRenderProps) => {
          const { handleSubmit, form, values } = formRenderProps;
          const { onIconClick, variant = 'create', isDuplicating } = props;
          const isReadyStepOne =
            values?.eventName &&
            values?.interests?.length &&
            values?.location &&
            values?.coverPhoto &&
            values?.eventDetails?.length > 0 &&
            [true, false]?.includes(values?.isRealOwner);

          const formTitle =
            variant === 'create'
              ? 'event:createEvent'
              : isDuplicating
              ? 'event:DuplicateEvent'
              : 'event:EditEvent';

          return (
            <>
              <FormWrapper
                ref={drawerContentRef}
                onSubmit={async (e) => {
                  e.preventDefault();

                  if (variant === 'create' || variant === 'duplicate') {
                    if (stepNumber < 3) {
                      setStepNumber((prev) => prev + 1);
                    } else if (values?.vehicle) {
                      await handleSubmit();
                    }
                  } else {
                    if (stepNumber === 2) {
                      await handleSubmit();
                    } else {
                      setStepNumber((prev) => prev + 1);
                    }
                  }
                }}
                onIconClick={() => {
                  if (stepNumber === 1) {
                    onIconClick();
                  } else {
                    setStepNumber((prev) => prev - 1);
                  }
                }}
                isHeaderBorder
                isCross={stepNumber === 1}
                headerText={
                  stepNumber === 1 ? t(formTitle) : t('event:optionalInfo')
                }
                submitText={
                  stepNumber === 1
                    ? t('common:nextText')
                    : t('editUserProfile:save')
                }
                disabled={!!(stepNumber === 1 && !isReadyStepOne)}
              >
                <StepNumberWrap>
                  <StepNumber mr={8} isSelectedStep={stepNumber === 1} />

                  <StepNumber mr={8} isSelectedStep={stepNumber === 2} />
                  {(variant === 'create' || variant === 'duplicate') && (
                    <StepNumber isSelectedStep={stepNumber === 3} />
                  )}
                </StepNumberWrap>
                {stepNumber === 1 && (
                  <StepOne
                    form={form}
                    data={data}
                    initialValues={values}
                    isDuplicating={isDuplicating}
                  />
                )}
                {stepNumber === 2 && (
                  <StepTwo
                    form={form}
                    initialValues={values}
                    isEventOwner={values?.isRealOwner}
                    eventStartDate={values?.startDate}
                    eventEndDate={values?.endDate}
                    eventTimeZone={values?.timezone}
                  />
                )}
                {stepNumber === 3 && (
                  <SelectVehicleWrapper>
                    <SelectVehicle stepNumber={3} />
                  </SelectVehicleWrapper>
                )}
              </FormWrapper>
            </>
          );
        }}
      />
    </>
  );
};

export default CreateEventPostForm;
