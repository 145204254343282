/* eslint-disable no-unused-expressions */
import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';

import {
  getInterests,
  getEvents,
  getFollowers,
  getGroups,
  addInterest,
  editProfileData,
  getMyEvents,
  getMyDiscussions,
  addUsername,
  finishTutorial,
  getSuggestion,
  reportUser,
  addGroup,
  getUserDetails,
  getUserPosts,
  getUserFollowers,
  getUserFollowing,
  removeUserFromMyFollowers,
  userGarageVehicles,
  getMyRoutes,
  joinGroup,
  unjoinGroup,
} from 'services';
import { InvolvedUserTypes } from 'utils/constants';
import {
  getGroupFollowersStart,
  getGroupAdminsV12Start,
  getGroupMembersStart,
  getEventInvitedStart,
  getEventAttendingStart,
  getEventWaitListingStart,
} from 'store/posts/actions';
import { getLocalStorage } from 'utils/hooks';
import {
  actionTypes,
  getInterestsSuccess,
  getInterestsError,
  getEventsSuccess,
  getEventsError,
  getFollowersSuccess,
  getFollowersError,
  getGroupsSuccess,
  getGroupsError,
  addInterestSuccess,
  addInterestError,
  editProfileDataSuccess,
  editProfileDataError,
  getMyEventsSuccess,
  getMyEventsError,
  getMyDiscussionsSuccess,
  getMyDiscussionsError,
  addUsernameSuccess,
  addUsernameError,
  finishTutorialSuccess,
  finishTutorialError,
  getSuggestionSuccess,
  getSuggestionError,
  reportUserSuccess,
  reportUserError,
  addGroupSuccess,
  addGroupError,
  getUserDetailsSuccess,
  getUserDetailsError,
  getUserDetailsStart,
  getUserPostsSuccess,
  getUserPostsError,
  getSideMyEventsSuccess,
  getSideMyEventsError,
  getSideGroupsSuccess,
  getSideGroupsError,
  getUserFollowersSuccess,
  getUserFollowersError,
  getUserFollowersStart,
  getUserFollowingSuccess,
  getUserFollowingError,
  getUserFollowingStart,
  removeUserFromMyFollowersSuccess,
  removeUserFromMyFollowersError,
  getUserGarageVehiclesSuccess,
  getUserGarageVehiclesError,
  getInvolvedUsers,
  getMyRoutesSuccess,
  getMyRoutesError,
  postJoinGroupSuccess,
  postJoinGroupError,
  postUnjoinGroupSuccess,
  postUnjoinGroupError,
} from './actions';
import { loadIdentity } from '../identity/actions';

function* workerGetInterests() {
  try {
    const resp = yield call(getInterests);
    if (resp) {
      yield put(getInterestsSuccess(resp.data));
    }
  } catch (err) {
    yield put(getInterestsError());
  }
}

function* workerGetEvents() {
  try {
    const resp = yield call(getEvents);

    yield put(getEventsSuccess(resp.data));
  } catch (err) {
    yield put(getEventsError());
  }
}

function* workerGetMyEvents(action) {
  try {
    const resp = yield call(getMyEvents, action.data);

    typeof resp.total === 'number'
      ? yield put(getMyEventsSuccess(resp.data))
      : yield put(getMyEventsError(resp.error.message));
  } catch (err) {
    yield put(getMyEventsError(err));
  }
}
function* workerGetMyDiscussions(action) {
  try {
    const resp = yield call(getMyDiscussions, action.data);

    typeof resp.total === 'number'
      ? yield put(getMyDiscussionsSuccess(resp.data))
      : yield put(getMyDiscussionsError(resp.error.message));
  } catch (err) {
    yield put(getMyDiscussionsError(err));
  }
}

function* workerGetMyRoutes(action) {
  try {
    const resp = yield call(getMyRoutes, action.data);
    if (resp.data) {
      yield put(getMyRoutesSuccess(resp.data?.publicRoutes));
    }
  } catch (err) {
    yield put(getMyRoutesError(err));
  }
}

function* workerGetFollowers(action) {
  try {
    const resp = yield call(getFollowers, action.data);

    typeof resp.total === 'number'
      ? yield put(getFollowersSuccess(resp.data))
      : yield put(getFollowersError());
  } catch (err) {
    yield put(getFollowersError());
  }
}

function* workerGetGroups(action) {
  try {
    const resp = yield call(getGroups, action.data);

    typeof resp.total === 'number'
      ? yield put(getGroupsSuccess(resp.data))
      : yield put(getGroupsError());
  } catch (err) {
    yield put(getGroupsError());
  }
}

function* workerAddInterest(interest) {
  try {
    const resp = yield call(addInterest, interest.data);

    resp.code === 200
      ? yield put(addInterestSuccess())
      : yield put(addInterestError('error'));
  } catch (err) {
    yield put(addInterestError(err));
  }
}

function* workerEditProfileData(data) {
  try {
    const resp = yield call(editProfileData, data.data);
    const userId = JSON.parse(getLocalStorage('user_id'));

    if (resp.status === 204) {
      yield put(editProfileDataSuccess());
      yield put(getUserDetailsStart(userId));
      yield put(loadIdentity());
    } else {
      yield put(editProfileDataError(resp.data));
    }
  } catch (err) {
    yield put(editProfileDataError(err));
  }
}

function* workerAddUsername(action) {
  try {
    const resp = yield call(addUsername, action.data);

    resp.status === 204
      ? yield put(addUsernameSuccess())
      : yield put(addUsernameError(resp.data.msg));
  } catch (err) {
    yield put(addUsernameError(err));
  }
}

function* workerFinishTutorial() {
  try {
    const resp = yield call(finishTutorial);

    if (resp.status === 204) {
      yield localStorage.removeItem('tutorialIsActive');
      yield put(finishTutorialSuccess());
    } else {
      yield put(finishTutorialError(resp.data.msg));
    }
  } catch (err) {
    yield put(finishTutorialError(err));
  }
}

function* workerGetSuggestion(action) {
  try {
    const resp = yield call(getSuggestion, action.data);

    console.log('suggestions', resp);

    resp.code === 200
      ? yield put(getSuggestionSuccess(resp.data))
      : yield put(getSuggestionError(resp.data));
  } catch (err) {
    yield put(getSuggestionError(err));
  }
}

function* workerReportUser(action) {
  try {
    const resp = yield call(reportUser, action.data);

    console.log('resp report user', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(reportUserSuccess())
      : yield put(reportUserError(resp.data));
  } catch (err) {
    yield put(reportUserError(err));
  }
}

function* workerAddGroup(action) {
  try {
    const resp = yield call(addGroup, action.data);

    console.log('resp add group', resp);

    resp.code === 200
      ? yield put(addGroupSuccess(resp.data))
      : yield put(addGroupError(resp.data));
  } catch (err) {
    yield put(addGroupError(err));
  }
}

function* workerGetUserDetails(action) {
  try {
    if (action?.data) {
      const resp = yield call(getUserDetails, action.data);
      resp.data
        ? yield put(getUserDetailsSuccess(resp.data))
        : yield put(getUserDetailsError(resp.data));
    }
  } catch (err) {
    yield put(getUserDetailsError(err));
  }
}

function* workerGetUserPosts(action) {
  try {
    const resp = yield call(getUserPosts, action.data);

    resp.code === 200 && resp.result === 'ok'
      ? yield put(getUserPostsSuccess(resp.data))
      : yield put(getUserPostsError(resp.data));
  } catch (err) {
    yield put(getUserPostsError(err));
  }
}

function* workerGetSideMyEvents(action) {
  const { offset = 0, limit = 10 } = action.payload || {};
  try {
    const params = { offset, limit };

    const resp = yield call(getMyEvents, params);

    const { total, data } = resp;

    typeof total === 'number'
      ? yield put(getSideMyEventsSuccess(data, total))
      : yield put(getSideMyEventsError('Error'));
  } catch (err) {
    yield put(getSideMyEventsError(err));
  }
}

function* workerGetSideGroups(action) {
  const { offset = 0, limit = 10 } = action.payload || {};
  try {
    const params = { offset, limit };

    const resp = yield call(getGroups, params);

    const { total, data } = resp;
    typeof total === 'number'
      ? yield put(getSideGroupsSuccess(data, total))
      : yield put(getSideGroupsError('Error'));
  } catch (err) {
    yield put(getSideGroupsError(err));
  }
}

function* workerGetUserFollowers(action) {
  try {
    const resp = yield call(getUserFollowers, action.data);

    typeof resp.total === 'number'
      ? yield put(
          getUserFollowersSuccess({ data: resp.data, total: resp.total })
        )
      : yield put(getUserFollowersError('error'));
  } catch (err) {
    yield put(getUserFollowersError(err));
  }
}

function* workerGetUserFollowing(action) {
  try {
    const resp = yield call(getUserFollowing, action.data);

    typeof resp.total === 'number'
      ? yield put(
          getUserFollowingSuccess({ data: resp.data, total: resp.total })
        )
      : yield put(getUserFollowingError('error'));
  } catch (err) {
    yield put(getUserFollowingError(err));
  }
}

function* workerRemoveUserFromMyFollowers(action) {
  try {
    const resp = yield call(removeUserFromMyFollowers, action.data);

    console.log('remove user from followers', resp);

    resp.status === 204 || resp.code === 200
      ? yield put(removeUserFromMyFollowersSuccess())
      : yield put(removeUserFromMyFollowersError('error'));
  } catch (err) {
    yield put(removeUserFromMyFollowersError(err));
  }
}

function* workerGetInvolvedUsers(action) {
  try {
    const { involvedUserType, id, offset, limit, query: search } = action.data;

    let params;

    switch (involvedUserType) {
      case InvolvedUserTypes.MY_FOLLOWERS:
      case InvolvedUserTypes.USER_FOLLOWERS:
        params = { userId: id, offset, limit, search };
        yield put(getUserFollowersStart(params));
        break;

      case InvolvedUserTypes.MY_FOLLOWING:
      case InvolvedUserTypes.USER_FOLLOWING:
        params = { userId: id, offset, limit, search };
        yield put(getUserFollowingStart(params));
        break;

      case InvolvedUserTypes.GROUP_FOLLOWERS:
        params = { groupId: id, offset, limit, search };
        yield put(getGroupFollowersStart(params));
        break;

      case InvolvedUserTypes.GROUP_MEMBERS:
        params = { groupId: id, offset, limit, search };
        yield put(getGroupMembersStart(params));
        break;

      case InvolvedUserTypes.GROUP_ADMINS:
        yield put(getGroupAdminsV12Start(id));
        break;

      case InvolvedUserTypes.EVENT_INVITED:
        yield put(getEventInvitedStart(id));
        break;

      case InvolvedUserTypes.EVENT_ATTENDING:
        yield put(getEventAttendingStart(id));
        break;

      case InvolvedUserTypes.EVENT_WAITLISTING:
        yield put(getEventWaitListingStart(id));
        break;

      default:
    }
  } catch (error) {
    console.log(error);
  }
}

function* workerGetUserGarageVehicles(action) {
  try {
    const resp = yield call(userGarageVehicles, action.data);

    console.log('u g', resp);

    resp.code === 200
      ? yield put(getUserGarageVehiclesSuccess(resp.data))
      : yield put(getUserGarageVehiclesError(resp.data));
  } catch (err) {
    yield put(getUserGarageVehiclesError(err));
  }
}

// Worker saga for joining a group
function* workerPostJoinGroup(action) {
  const { onSuccess } = action?.data || {};
  try {
    const resp = yield call(joinGroup, action.data);
    if (resp.ok) {
      yield put(
        postJoinGroupSuccess({ ...action.data?.data, onSuccess: undefined })
      );
    } else {
      yield put(postJoinGroupError('Error joining group'));
    }
    
    onSuccess && onSuccess(resp);
  } catch (err) {
    yield put(postJoinGroupError(err));
    onSuccess && onSuccess({});
  }
}

// Worker saga for unjoining a group
function* workerPostUnjoinGroup(action) {
  const { onSuccess } = action?.data || {};
  try {
    const resp = yield call(unjoinGroup, action.data?.groupId);

    if (resp.ok) {
      yield put(postUnjoinGroupSuccess(action.data?.groupId));
    } else {
      yield put(postUnjoinGroupError('Error unjoining group'));
    }
    onSuccess && onSuccess(resp);
  } catch (err) {
    yield put(postUnjoinGroupError(err));
    onSuccess && onSuccess({});
  }
}

function* watchUser() {
  yield takeEvery(actionTypes.GET_INTERESTS_REQUEST, workerGetInterests);
  yield takeEvery(actionTypes.GET_EVENTS_REQUEST, workerGetEvents);
  yield takeEvery(actionTypes.GET_FOLLOWERS_REQUEST, workerGetFollowers);
  yield takeEvery(actionTypes.GET_GROUPS_REQUEST, workerGetGroups);
  yield takeEvery(actionTypes.ADD_INTEREST_START, workerAddInterest);
  yield takeEvery(actionTypes.EDIT_PROFILE_DATA_START, workerEditProfileData);
  yield takeEvery(actionTypes.GET_MY_EVENTS_START, workerGetMyEvents);
  yield takeEvery(actionTypes.GET_MY_DISCUSSIONS_START, workerGetMyDiscussions);
  yield takeEvery(actionTypes.GET_MY_ROUTES_START, workerGetMyRoutes);
  yield takeEvery(actionTypes.ADD_USERNAME_START, workerAddUsername);
  yield takeEvery(actionTypes.FINISH_TUTORIAL_START, workerFinishTutorial);
  yield takeLeading(actionTypes.GET_SUGGESTION_START, workerGetSuggestion);
  yield takeEvery(actionTypes.REPORT_USER_START, workerReportUser);
  yield takeEvery(actionTypes.ADD_GROUP_START, workerAddGroup);
  yield takeEvery(actionTypes.GET_USER_DETAILS_START, workerGetUserDetails);
  yield takeEvery(actionTypes.GET_USER_POSTS_START, workerGetUserPosts);
  yield takeEvery(actionTypes.GET_SIDE_MY_EVENTS_START, workerGetSideMyEvents);
  yield takeEvery(actionTypes.GET_SIDE_GROUPS_START, workerGetSideGroups);
  yield takeEvery(actionTypes.GET_USER_FOLLOWERS_START, workerGetUserFollowers);
  yield takeEvery(actionTypes.GET_USER_FOLLOWING_START, workerGetUserFollowing);
  yield takeEvery(
    actionTypes.REMOVE_USER_FROM_MY_FOLLOWERS_START,
    workerRemoveUserFromMyFollowers
  );
  yield takeEvery(actionTypes.GET_INVOLVED_USERS, workerGetInvolvedUsers);
  yield takeEvery(
    actionTypes.GET_USER_GARAGE_VEHICLES_START,
    workerGetUserGarageVehicles
  );
  yield takeEvery(actionTypes.POST_JOIN_GROUP_START, workerPostJoinGroup);
  yield takeEvery(actionTypes.POST_UNJOIN_GROUP_START, workerPostUnjoinGroup);
}

export default watchUser;
