import cookieCutter from 'cookie-cutter';
import API_NAME from 'services/api';
import { getClubIdFromCookies } from './storageUtils';
import { error } from 'console';

type FetchDataOptions = RequestInit & {
  headers?: {
    Accept?: string;
    'Content-Type'?: string;
    tsec?: string;
    clubId?: string;
  };
  isAuthenticated?: boolean;
};

const fetchData = async (url: RequestInfo | URL, options: FetchDataOptions) => {
  const clubId = options?.headers?.clubId || getClubIdFromCookies();
  const mergedHeaders: any = {
    ...options.headers,
  };
  if (clubId && !String(url)?.includes('/auth')) {
    mergedHeaders.activeClubId = clubId;
  }
  const customOptions: RequestInit | any = {
    ...options,
    headers: mergedHeaders,
  };
  const isAuthenticated = options?.isAuthenticated ?? true;

  if (isAuthenticated && !customOptions?.headers?.tsec) {
    return Promise.reject({
      error: true,
      code: 401,
      message: 'Unauthorized: Token is required',
    });
  }

  const formattedUrl = typeof url === 'string' ? url : url.toString();
  const response = await fetch(formattedUrl, customOptions);
  return response;
};

export default fetchData;
