import styled from 'styled-components';
import Heading from 'components/common/Typography/Heading';

interface IProps {
  pt?: number;
  pb?: number;
  mt?: number;
  mb?: number;
  fw?: number;
  fs?: number;
  mr?: number;
  isSelectedStep?: boolean;
  selected?: boolean;
  italic?: boolean;
  checked?: boolean;
  ref?: any;
}

export const MainContainer = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  position: relative;
  padding-bottom:80px;
`;
export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  position: sticky;
  top: 0;
  z-index: 100;
  padding-top:16px;
  padding-bottom:16px;
  background-color: white;
  border-bottom: 1px solid #ccc;  // Add border-bottom as a separator
`;
export const SectionWrapper = styled.div<IProps>`
  position: relative;
  padding-top: ${({ pt }) => (pt ? `${pt}px` : '20px')};
  padding-bottom: ${({ pb }) => (pb ? `${pb}px` : '16.5px')};
  border-bottom: 1px dashed ${(props) => props.theme.colors.neutral.n4};
  &:nth-child(3) {
    padding-top: 0;
  }
  :last-of-type {
    border: none;
  }
`;
export const SeparateSettingsHeader = styled(Heading)<IProps>`
  font-size: ${({ fs, theme }) =>
    fs ? `${fs}px` : theme.typography.fontSize.f6};
  line-height: 150%;
  font-weight: ${({ fw }) => fw || '600'};
  letter-spacing: 0.02em;
  color: ${(props) => props.theme.colors.text};
  margin-top: ${({ mt }) => (mt ? `${mt}px` : '0')};
  margin-bottom: ${({ mb }) => (mb ? `${mb}px` : '0')};
`;
export const SelectWrapper = styled.div`
  position: relative;
  height: 44px;
  width: 100%;
  max-width: 260px;
`;
export const SelectIconWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 10px;
  left: 8px;
`;
export const TimeZoneText = styled.div`
  height: 44px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: ${(props) => props.theme.colors.neutral.n0};
  color: ${(props) => props.theme.colors.black};
  font-size: 15px;
  line-height: 44px;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
  position: absolute;
  z-index: -1;
  left: 0;
  right: 0;
`;

export const Select = styled.select`
  height: 44px;
  width: 100%;
  max-width: 260px;
  padding: 0 16px 0 38px;
  border-radius: 4px;
  background-color: transparent;
  color: transparent;
  font-size: 15px;
  line-height: 100%;
  letter-spacing: 0.02em;
  border: none;
  outline: none;
  cursor: pointer;
  appearance: none;
  font-family: 'Poppins';
`;
export const HeaderDivider = styled.div`
width: 100%;
display:flex;
align-items:center;
justify-content:flex-end;
`